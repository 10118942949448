import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import useDocumentTitle from 'src/hooks/use-document-title';
import { StaticIdentifiers } from 'src/constants/cluster-status';
import { Button, FaIcon, Select } from '@in/component-library';
import { useSearchParams } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import useDownloadGoalOverviewReport from 'src/features/siva/reporting/hooks/useDownloadGoalOverviewReport';
import saveAs from 'file-saver';

const pageSearchParams = {
  year: 'year',
  actorType: 'actorType',
};

const ExtractGoalAccomplishment: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { t: tSiva } = useTranslation('siva');
  const { documentTitle } = useDocumentTitle(tPageTitles('sivaReporting'));
  const [searchParams, setSearchParams] = useSearchParams();

  const actorType = searchParams.get(pageSearchParams.actorType) || StaticIdentifiers.SivaBusinessGarden;

  const { fetchReport } = useDownloadGoalOverviewReport(actorType);

  const handleDownloadReportOnClick = async () => {
    const file = await fetchReport.mutateAsync();
    saveAs(file.data, file.fileName);
  };

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <h1>{tSiva('export.goalAccomplishment.header')}</h1>
        <div className="margin-top--6 margin-bottom--3 display--flex gap--3 align-items--center">
          <Select
            name={tCommon('type')}
            label={tCommon('type')}
            options={[
              {
                text: tCommon('businessGarden'),
                value: StaticIdentifiers.SivaBusinessGarden,
              },
              {
                text: tCommon('incubator'),
                value: StaticIdentifiers.SivaIncubator,
              },
            ]}
            value={actorType}
            onChange={(actorType) =>
              setSearchParams(
                (prevValue) => {
                  const year = prevValue.get(pageSearchParams.year) || '';
                  return { actorType, year };
                },
                { replace: true },
              )
            }
          />
          <Button onClick={handleDownloadReportOnClick} variant="link">
            <FaIcon
              className={'margin-right--1'}
              icon={icon({ prefix: 'fal', iconName: 'file-export' }) as IconDefinition}
              size="1x"
            />
            {`${tSiva('export.goalAccomplishment.exportButton')} ${
              actorType === StaticIdentifiers.SivaBusinessGarden
                ? tCommon('businessGarden').toLowerCase()
                : tCommon('incubator').toLowerCase()
            }`}
          </Button>
        </div>
      </Page>
    </DocumentTitle>
  );
};

export default ExtractGoalAccomplishment;
