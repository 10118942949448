import { Button, FaIcon, FaIcons, Toast } from '@in/component-library';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SivaContractOverviewDataTable from '../components/SivaContractOverviewDataTable';
import { useSivaContracts } from '../hooks/use-siva-contracts';
import { ROUTES } from 'src/navigation';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/features/authorization';
import { useSivaOverviewTabs } from '../hooks/use-siva-overview-tabs';
import { useSivaContractAllDataExtract } from '../hooks/use-siva-contract-data-extract';
import saveAs from 'file-saver';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import { HttpStatusCode, SivaContractOverviewDto, SivaPhase } from 'src/api/v2';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import useCompanyContract from '../company-details/hooks/use-company-contract';

interface ToastProps {
  visible: boolean;
  header: string;
  text: string;
  type: 'success' | 'error' | undefined;
}

const SivaContractsIndexPage: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { t: tPageTitles } = useTranslation('pageTitles');

  const navigate = useNavigate();

  const { checkAccess } = useAuth();
  const { read: isBusinessGarden } = checkAccess('UI_SivaBusinessGarden');
  const title = isBusinessGarden ? tPageTitles('targetedCompanies') : tSiva('ideasAndCompanies.title');

  const { contracts, isLoading, mutations } = useSivaContracts();
  const { download } = useSivaContractAllDataExtract();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState<SivaContractOverviewDto[]>([]);

  const { availablePhasesForTab, tabElements, columnsForTab, activeTabName } =
    useSivaOverviewTabs(isBusinessGarden);
  const { revertSivaContractMutation } = useCompanyContract(undefined);

  const getToastProps = (toastType?: 'error' | 'successTerminate' | 'successReopen'): ToastProps => {
    switch (toastType) {
      case 'error':
        return {
          header: tSiva('ideasAndCompanies.generalErrorHeader'),
          text: tSiva('ideasAndCompanies.generalErrorMessage'),
          type: 'error',
          visible: true,
        };
      case 'successReopen':
        return {
          header:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasCompaniesReopenedPlural', { count: selectedRows.length })
              : tSiva('ideasAndCompanies.ideasCompaniesReopenedSingular', { count: selectedRows.length }),
          text: '',
          type: 'success',
          visible: true,
        };
      case 'successTerminate':
        return {
          header:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasTerminatedPlural.header', { count: selectedRows.length })
              : tSiva('ideasAndCompanies.ideasTerminatedSingular.header', { count: selectedRows.length }),
          text:
            selectedRows.length > 1
              ? tSiva('ideasAndCompanies.ideasTerminatedPlural.text')
              : tSiva('ideasAndCompanies.ideasTerminatedSingular.text'),
          visible: true,
          type: 'success',
        };
      default:
        return {
          header: '',
          text: '',
          visible: false,
          type: undefined,
        };
    }
  };

  const [toastProps, setToastProps] = useState<ToastProps>(getToastProps());

  const batchTerminateContracts = async () => {
    setShowDialog(false);
    setToastProps(getToastProps());
    const numericIds = selectedRows.map((row) => row.numericId);
    const phase = SivaPhase.Alumni;
    const fromDate = new Date();
    await mutations.batchUpdatePhase
      .mutateAsync({ numericIds, phase, fromDate })
      .catch(() => setToastProps(getToastProps('error')))
      .then((operationResults) => {
        if (operationResults != null && operationResults.some((or) => or.status != HttpStatusCode.OK)) {
          setToastProps(getToastProps('error'));
          return;
        }
        setToastProps(getToastProps('successTerminate'));
      });
  };

  const batchReopenContracts = async () => {
    setShowDialog(false);
    setToastProps(getToastProps());
    const numericIds = selectedRows.map((row) => row.numericId);
    const promises: Promise<void>[] = [];
    for (const numericId of numericIds) {
      promises.push(revertSivaContractMutation.mutateAsync(numericId));
    }
    Promise.all(promises)
      .then(() => setToastProps(getToastProps('successReopen')))
      .catch(() => setToastProps(getToastProps('error')));
  };

  const TerminateContractsDialog = () => {
    return (
      <DialogContainer
        title={tSiva('ideasAndCompanies.terminateChosenIdeas')}
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <p>
          {`${tSiva('ideasAndCompanies.wantToTerminate')} ${selectedRows.length} ${selectedRows.length > 1 ? tSiva('ideasAndCompanies.chosenIdeasPlural') : tSiva('ideasAndCompanies.chosenIdeasSingular')}`}
        </p>
        <div className="display--flex gap--1">
          <Button onClick={batchTerminateContracts}>{tSiva('ideasAndCompanies.terminate')}</Button>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {tCommon('cancel')}
          </Button>
        </div>
      </DialogContainer>
    );
  };

  const ReopenContractsDialog = () => {
    return (
      <DialogContainer
        title={tSiva('ideasAndCompanies.reopenChosenIdeasCompanies')}
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <p>{`${tSiva('ideasAndCompanies.wantToReopen')} ${selectedRows.length} ${selectedRows.length > 1 ? tSiva('ideasAndCompanies.chosenIdeasCompaniesPlural') : tSiva('ideasAndCompanies.chosenIdeasCompaniesSingular')}`}</p>
        <div className="display--flex gap--1">
          <Button onClick={batchReopenContracts}>
            {tSiva('ideasAndCompanies.reopenChosenIdeasCompanies')}
          </Button>
          <Button variant="outlined" onClick={() => setShowDialog(false)}>
            {tCommon('cancel')}
          </Button>
        </div>
      </DialogContainer>
    );
  };

  if (isLoading || mutations.batchUpdatePhase.isLoading || revertSivaContractMutation.isLoading) {
    return (
      <main className="full-width-layout">
        <h1 className="margin-bottom--6">{title}</h1>
        <LoadingPage />
      </main>
    );
  }

  return (
    <main className="full-width-layout">
      <h1 className="margin-bottom--6">{title}</h1>
      {contracts.length === 0 && (
        <div className="display--flex flex-direction--column align-items--flex-start gap--3 margin-bottom--6">
          <div className="display--flex align-items--center gap--2">
            <FaIcon className="font-size--40px" name={FaIcons.SparklesLight} />
            <FaIcon className="font-size--40px" name={FaIcons.BuildingLight} />
          </div>
          <h2 className="margin-y--0">{tSiva('ideasAndCompanies.notFound')}</h2>
        </div>
      )}

      {checkAccess('SivaCore').write && (
        <div className="display--flex gap--1">
          <Button
            type="button"
            iconName={FaIcons.PlusLight}
            onClick={() => {
              navigate(ROUTES.SIVA.IDEAS_AND_COMPANIES.NEW);
            }}
          >
            {tCommon('addNew')}
          </Button>
          <Button
            type="button"
            variant="outlined"
            iconName={FaIcons.FileDownloadLight}
            onClick={async () => {
              const file = await download.mutateAsync(false);
              saveAs(file.data, file.fileName);
            }}
          >
            {tSiva('export.ideasAndCompanies.exportTotal')}
          </Button>
        </div>
      )}

      {contracts.length > 0 && (
        <>
          {tabElements}
          <SivaContractOverviewDataTable
            availablePhases={availablePhasesForTab}
            columns={columnsForTab}
            setShowDialog={setShowDialog}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            activeTabName={activeTabName}
          />
          {activeTabName == 'ideas' && <TerminateContractsDialog />}
          {activeTabName == 'alumni' && <ReopenContractsDialog />}
          <Toast
            visible={toastProps.visible}
            header={toastProps.header}
            icon={
              <FaIcon
                className={`${toastProps.type == 'success' && 'color--in-green-200'} ${toastProps.type == 'error' && 'color--red'}`}
                name={FaIcons.InfoCircle}
              />
            }
            position="bottom-center"
            text={toastProps.text}
          />
        </>
      )}
    </main>
  );
};

export default SivaContractsIndexPage;
