const resourceBase_baseRoute = `/resource-base`;

const clusterMember_helperRoutes = (category: 'members' | 'associates' | 'leads', id: string) => {
  const base = `${resourceBase_baseRoute}/${category}/${id}`;

  return {
    INDEX: `${base}`,
    INFO: `${base}/info`,
    CONTACTS: `${base}/contacts`,
    INTEREST_AREAS: `${base}/interest-areas`,
    FEES_AND_INKNIND: `${base}/fees-and-inkind`,
    COMPANY_INFORMATION: `${base}/company-information`,
  };
};

export const HOME_ROUTE = '/';
export const TERMS_OF_USE_ROUTE = '/terms-of-use';

export const ONE_WAY_IN_BASE_ROUTE = '/opportunities';

export const RESOURCE_BASE = {
  INDEX: `${resourceBase_baseRoute}`,
  MEMBERS: `${resourceBase_baseRoute}/members`,
  CONTACTS: `${resourceBase_baseRoute}/contacts`,
  ASSOCIATES: `${resourceBase_baseRoute}/associates`,
  LEADS: `${resourceBase_baseRoute}/leads`,
};

export const MEMBER_ROUTES = (id: string) => clusterMember_helperRoutes('members', id);
export const ASSOCIATE_ROUTES = (id: string) => clusterMember_helperRoutes('associates', id);
export const LEAD_ROUTES = (id: string) => clusterMember_helperRoutes('leads', id);

export const CONTACT_ROUTES = (id: string) => {
  const BASE = `${resourceBase_baseRoute}/contacts/${id}`;

  return {
    INDEX: `${BASE}`,
    CONTACT_INFROMATION: `${BASE}/contact-information`,
    INTEREST_AREAS: `${BASE}/interest-areas`,
    INKIND: `${BASE}/inkind`,
  };
};

export const ACCOUNT_SETTINGS_BASE_ROUTE = `/account-settings`;

export const CLUSTER_CHOOSER_BASE_ROUTE = `/choose-cluster`;

// TODO: Fullfør dette, legger bare til i PR for å få inn en TODO vi kan ta tak i
export const ACCOUNT_SETTINGS = {
  INDEX: `${ACCOUNT_SETTINGS_BASE_ROUTE}`,
  BUSINESS_DESCRIPTIONG: {
    PROJECT_CATEGORIES: `${ACCOUNT_SETTINGS_BASE_ROUTE}/business-description/project-categories`,
  },
};

export const ACTOR_PROFILE_BASE_ROUTE = '/actor-profile';

export const CONTACT_INFORMATION_ROUTE = '/contact-information';

export const TRACKING_BASE_ROUTE = '/track';
