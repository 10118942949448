//Vi må legitimt skrive om store deler av logikk på klyngeportalen for å fjerne dette.
//Noe av det brukes også på Siva som dropdown valg og queryparameter.
export const StaticIdentifiers = {
  ClusterInitiative: '49527767-f08c-4dc9-b537-abe333fda58c',
  Arena: 'c53b2d53-def2-4cf3-a29e-7816f09a71e9',
  ArenaPro: '00732128-4a69-4489-82f7-c35be1ffb466',
  Nce: 'd4defd0c-66f3-48e2-ae6e-5fe98d93f2ba',
  NceOutsideNic: '3ee05649-e9c4-4f06-a3b5-1a4d00da3fda',
  Gce: '50d613ca-28bc-4664-bc38-fda1187911ac',
  MatureClusters: 'e879bb8a-a682-491d-a3cd-b3d4a8d14032',
  BusinessNetwork: '1441db79-d147-4181-b1aa-9b2719ddb839',
  Network: '3fc85695-240f-41d1-a7d4-cf0d3b7d2877',
  OneWayIn: '26addf9d-4d40-47e2-b204-0f3f46a92dba',
  SivaAdminDev: 'bc43eabe-1249-4f8f-63fe-08dbe45624a9',
  SivaAdminTest: 'b8ad97b3-aa74-4d61-f9d1-08dbf250371e',
  SivaAdminProd: 'bb407aed-be3b-412a-2b55-08dbe68e9367',
  SivaBusinessGarden: 'bcabb9fb-a716-49c1-0f03-08dbb82f9879',
  SivaIncubator: '0e9ae81b-7499-49f7-0f04-08dbb82f9879',
  NotSet: 'c4a42dcf-ce43-4431-9b73-4d624a9fe89f',
} as const;

export const StatusCodes = {
  ClusterInitiative: 'CLUSTER_INITIATIVE',
  Arena: 'ARENA',
  ArenaPro: 'ARENA_PRO',
  Nce: 'NCE',
  NceOutsideNic: 'NCE_OUTSIDE_NIC',
  Gce: 'GCE',
  MatureClusters: 'MODNE_KLYNGER',
  BusinessNetwork: 'BUSINESS_NETWORK',
  Network: 'NETWORK',
  OneWayIn: 'ONE_WAY_IN',
  SivaAdminDev: 'SIVA_ADMIN_STATUS',
  SivaAdminTest: 'SIVA_PROGRAMADMIN',
  SivaAdminProd: 'SIVA_PROGRAMADMIN',
  SivaBusinessGarden: 'BUSINESS_GARDEN',
  SivaIncubator: 'INCUBATOR',
  NotSet: 'NOT_SET',
} as const;

export type ClusterStatusCodes = keyof typeof StatusCodes;
