import React, { useMemo, useState } from 'react';
import { Button, TextArea } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import useOneWayIn from '../../hooks/use-one-way-in';
import { useParams } from 'react-router-dom';
import { OneWayInStatus } from 'src/api/v2';
import { useUser } from 'src/features/authorization';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';
import { useSupportEmail } from 'src/hooks/use-support-email';
import ArchivedMessage from '../components/ArchivedMessage';
import { PageParams } from '../types/PageParams';
import createOwiUpdateDto from '../utils/createOwiUpdateDto';

const AddNote = () => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tCommon } = useTranslation();
  const [comment, setComment] = useState('');
  const [commentWarning, setCommentWarning] = useState(false);
  const { isActive, activate, deactivate } = useToggle(false);
  const [modalContent, setModalContent] = useState({
    message: <div></div>,
    title: tCommon('confirmation'),
  });
  const { mailto, email } = useSupportEmail();
  const { formId } = useParams<PageParams>();
  const { oneWayInOpportunityFormQuery, updateOneWayInOpportunityStatus } = useOneWayIn(formId);
  const { isAadUser, user, displayName } = useUser();

  const opportunity = useMemo(() => {
    if (!oneWayInOpportunityFormQuery.data) return undefined;

    return oneWayInOpportunityFormQuery.data.opportunity;
  }, [oneWayInOpportunityFormQuery.data]);

  const createUpdateDto = (status: OneWayInStatus, comment: string) =>
    createOwiUpdateDto(
      opportunity?.id,
      status,
      comment,
      isAadUser ? undefined : user?.clusterUserId,
      displayName || undefined,
    );

  const onError = () => {
    setModalContent({
      message: (
        <span>
          {`${tOneWayIn('details.AddNote.Error.Message')}`}
          <a href={mailto}>{email}</a>.
        </span>
      ),
      title: tOneWayIn('details.AddNote.Error.Title'),
    });
  };

  const onSuccess = () => {
    setModalContent({
      message: <span>{tOneWayIn('details.AddNote.Success.Message')}.</span>,
      title: tCommon('confirmation'),
    });

    setComment('');
  };

  const onComment = (comment: string) => {
    const promise = updateOneWayInOpportunityStatus.mutateAsync(
      createUpdateDto(OneWayInStatus.Active, comment),
    );
    promise.then(onSuccess).catch(onError).finally(activate);
  };

  const onAddCommentClick = () => {
    if (comment === '') {
      setCommentWarning(true);
      return;
    }

    onComment(comment);
    setCommentWarning(false);
  };

  if (opportunity?.isClosed) {
    return <ArchivedMessage />;
  }

  return (
    <div className="width--50">
      <h2 className="margin-bottom--3">{tCommon('addNote')}</h2>
      <div className="margin-y--3">{tOneWayIn('details.AddNode.Intro')}</div>
      <div className="margin-top--2 margin-bottom--4">
        <TextArea
          label={`${tCommon('note')}*`}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxCount={1000}
          maxLength={1000}
          errorMsg={commentWarning ? `${tOneWayIn('details.AddNote.Required')}.` : ''}
        />
      </div>

      <Button
        onClick={onAddCommentClick}
        isLoading={updateOneWayInOpportunityStatus.isLoading}
        disabled={updateOneWayInOpportunityStatus.isLoading}
      >
        {tCommon('addNote')}
      </Button>
      <DialogContainer
        className="one-way-in-details__modal"
        showCloseButton
        title={modalContent.title}
        show={isActive}
        onClose={deactivate}
        titleHeadingLevel={2}
      >
        <div className="display--flex flex-direction--column">
          <div>{modalContent.message}</div>
          <Button onClick={deactivate} className="width--20 margin-top--6">
            {tCommon('close')}
          </Button>
        </div>
      </DialogContainer>
    </div>
  );
};

export default AddNote;
