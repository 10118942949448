import { SivaContractOverviewDto, SivaPhase } from '../../../../api/v2';
import React, { useMemo, useState } from 'react';
import { Tabs } from '@in/component-library';
import { ColumnDef } from '@tanstack/react-table';
import {
  admissionDateColumn,
  advisorColumn,
  companyNameOrIdeaNameColumn,
  endDateColumn,
  esaColumn,
  goalReportingColumn,
  numericIdColumn,
  phaseColumn,
  registrationDateColumn,
  selfDeclarationColumn,
  statusColumn,
} from './use-siva-overview-columns';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/features/authorization';

export interface TabDefinition {
  name: 'targetCompanies' | 'ideas' | 'contracts' | 'alumni';
  availablePhases: SivaPhase[];
  columns: ColumnDef<SivaContractOverviewDto, any>[];
}

const targetedTab = {
  name: 'targetCompanies' as const,
  availablePhases: [SivaPhase.Targeted],
  columns: [
    companyNameOrIdeaNameColumn,
    admissionDateColumn,
    advisorColumn,
    selfDeclarationColumn,
    goalReportingColumn,
    esaColumn,
  ],
};
const ideasTab = {
  name: 'ideas' as const,
  availablePhases: [SivaPhase.Idea],
  columns: [
    companyNameOrIdeaNameColumn,
    statusColumn,
    numericIdColumn,
    advisorColumn,
    registrationDateColumn,
  ],
};
const alumniTab = {
  name: 'alumni' as const,
  availablePhases: [SivaPhase.Alumni],
  columns: [companyNameOrIdeaNameColumn, statusColumn, numericIdColumn, advisorColumn, endDateColumn],
};

export const useSivaOverviewTabs = (isBusinessGarden: boolean) => {
  const { t: tSiva } = useTranslation('siva');
  const { checkAccess } = useAuth();
  const alumniTabActive = checkAccess('FF_AlumniTab').read;

  //TODO Fjern feature flag #90824
  const contractsTab = useMemo(
    () => ({
      name: 'contracts' as const,
      availablePhases: alumniTabActive
        ? [SivaPhase.PreIncubation, SivaPhase.Incubated, SivaPhase.Fi, SivaPhase.ScaleUp]
        : [SivaPhase.PreIncubation, SivaPhase.Incubated, SivaPhase.Fi, SivaPhase.ScaleUp, SivaPhase.Alumni],
      columns: [
        companyNameOrIdeaNameColumn,
        phaseColumn,
        admissionDateColumn,
        advisorColumn,
        selfDeclarationColumn,
        goalReportingColumn,
        esaColumn,
        numericIdColumn,
      ],
    }),
    [alumniTabActive],
  );
  const tabs: TabDefinition[] = useMemo(() => {
    //TODO Fjern feature flag #90824
    return alumniTabActive
      ? isBusinessGarden
        ? [targetedTab, alumniTab]
        : [contractsTab, ideasTab, alumniTab]
      : isBusinessGarden
        ? [targetedTab]
        : [contractsTab, ideasTab];
  }, [alumniTabActive, contractsTab, isBusinessGarden]);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = tabs.find((tab) => tab.name === searchParams.get('tab'));
  const [activeTabName, setActiveTabName] = useState(initialState?.name || tabs[0].name);

  const activeTab = useMemo(
    () => tabs.find((t) => t.name === activeTabName) ?? tabs[0],
    [activeTabName, tabs],
  );

  const handleChangeTab = (tabIndex: number) => {
    setActiveTabName(tabs[tabIndex].name);
    setSearchParams({ tab: tabs[tabIndex].name }, { replace: true });
  };

  const tabElements = (
    <Tabs
      className="margin-top--6"
      setActiveTab={(tabIndex: number) => handleChangeTab(tabIndex)}
      tabs={tabs.map((tab) => ({
        'aria-label': tSiva(`overviewTabs.${tab.name}`),
        'aria-selected': tab === activeTab,
      }))}
    />
  );

  return {
    tabElements,
    availablePhasesForTab: activeTab.availablePhases,
    columnsForTab: activeTab.columns,
    activeTabName: activeTabName,
  };
};
