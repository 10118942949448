import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { Link } from 'react-router-dom';
import './ReportingLink.scss';

type ReportingLinkType = {
  to: string;
  title: string;
  infoText?: string;
};

const ReportingLink: React.FC<ReportingLinkType> = ({ to, title, infoText }) => {
  //TODO: Tastaturnavigasjon må gjøres mer synlig
  return (
    <Link to={to} className="reporting-link">
      <p className="reporting-link__title">{title}</p>
      <FaIcon name={FaIcons.ArrowRight} className="reporting-link__icon" />
      {infoText && <span className="reporting-link__info-text">{infoText}</span>}
    </Link>
  );
};

export default ReportingLink;
