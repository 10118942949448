import React, { useMemo } from 'react';
import { Button, FaIcon } from '@in/component-library';
import useCompanyContract from '../hooks/use-company-contract';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { NavLink, useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'src/utils/string';
import EditContract from '../components/EditContract';
import ErrorSupportMessage from 'src/features/siva/components/ErrorSupportMessage';
import { convertIdToNumber } from '../utils/idConversion';

import '../styles/Contract.scss';
import EditPhase from '../components/EditPhase';
import { SivaPhase } from 'src/api/v2';
import ContractHistoric from '../components/ContractHistoric';
import EndContract from '../components/EndContract';
import { useAuth } from 'src/features/authorization';
import { ROUTES } from 'src/navigation';
import RevertContract from '../components/RevertContract';
import useGeonorge from 'src/features/one-way-in/hooks/use-geonorge';
import DeleteContractDialog from '../components/DeleteContractDialog';

const CompanyContract: React.FC = () => {
  const { contractId } = useParams<PageParams>();
  const {
    contract,
    company,
    isLoading,
    isError,
    contractAdmissionDate,
    contractEndDate,
    currentPhase,
    admissionFormDate,
    enrouteFormDate,
    exitFormDate,
    companyContractsCount,
  } = useCompanyContract(convertIdToNumber(contractId));
  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { checkAccess } = useAuth();
  const { getMunicipalityNameByNumber } = useGeonorge();

  const sivaCoreWriteAccess = checkAccess('SivaCore').write;
  const isBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;

  const [subpage, setSubpage] = React.useState<'edit' | 'phase' | 'endContract' | 'revert' | 'none'>('none');
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const linkToCompanyContracts = useMemo(
    () =>
      companyContractsCount > 1 && company ? (
        <div className="margin-top--5">
          <NavLink
            to={ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.CONTRACTS(contractId!)}
            className="siva-contract-overview__back-link"
          >
            <FaIcon className="margin-right--1" icon={icon({ prefix: 'fal', iconName: 'arrow-left' })} />{' '}
            {tSiva('company.details.contract.backToIdeasOrContracts')}
          </NavLink>
        </div>
      ) : (
        <div className="margin-top--5">
          <NavLink to={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE} className="siva-contract-overview__back-link">
            <FaIcon className="margin-right--1" icon={icon({ prefix: 'fal', iconName: 'arrow-left' })} />{' '}
            {tSiva('company.details.contract.backToIdeasOrContracts')}
          </NavLink>
        </div>
      ),
    [company, companyContractsCount, contractId, tSiva],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!contract || isError) {
    return (
      <div>
        {linkToCompanyContracts}
        <div className="margin-top--5 margin-left--3">
          <h2>{tSiva('company.details.contract.overview.error.header')}</h2>
          <ErrorSupportMessage />
        </div>
      </div>
    );
  }

  switch (subpage) {
    case 'edit':
      return <EditContract onCancel={() => setSubpage('none')} />;

    case 'phase':
      return <EditPhase onCancel={() => setSubpage('none')} />;
    case 'revert':
      return <RevertContract onCancel={() => setSubpage('none')} />;
    case 'endContract':
      return <EndContract onCancel={() => setSubpage('none')} />;

    default:
      return (
        <div>
          {linkToCompanyContracts}
          <div className="display--flex margin-bottom--5">
            <Button
              className="siva-contract-overview__buttons"
              iconName="fa-edit"
              iconPosition="left"
              size="medium"
              theme="neutral"
              variant="link"
              onClick={() => setSubpage('edit')}
            >
              {tSiva('company.details.contract.editContract')}
            </Button>
            {sivaCoreWriteAccess &&
              !isBusinessGarden &&
              currentPhase?.sivaPhase !== SivaPhase['Alumni'] &&
              currentPhase?.sivaPhase !== SivaPhase['ScaleUp'] && (
                <Button
                  className="siva-contract-overview__buttons"
                  iconName="fa-light fa-arrow-right"
                  iconPosition="left"
                  size="medium"
                  theme="neutral"
                  variant="link"
                  onClick={() => setSubpage('phase')}
                >
                  {tSiva('company.details.contract.changePhase')}
                </Button>
              )}
            {sivaCoreWriteAccess && !isBusinessGarden && contract.phases.length !== 1 && (
              <Button
                className="siva-contract-overview__buttons"
                iconName="fa-light fa-arrow-left"
                iconPosition="left"
                size="medium"
                theme="neutral"
                variant="link"
                onClick={() => setSubpage('revert')}
              >
                {tSiva('company.details.contract.revertPhase')}
              </Button>
            )}
            {sivaCoreWriteAccess && currentPhase?.sivaPhase !== SivaPhase['Alumni'] && (
              <Button
                onClick={() => setSubpage('endContract')}
                className="siva-contract-overview__buttons"
                size="medium"
                theme="neutral"
                variant="link"
              >
                <FaIcon
                  className="margin-right--1"
                  icon={icon({ prefix: 'fal', iconName: 'circle-xmark' })}
                />
                {tSiva('company.details.contract.endContract')}
              </Button>
            )}
            {sivaCoreWriteAccess && (
              <Button
                className="siva-contract-overview__buttons"
                iconName="fa-light fa-trash-alt"
                iconPosition="left"
                size="medium"
                theme="neutral"
                variant="link"
                onClick={() => setShowDeleteDialog(true)}
              >
                {tCommon('delete')}
              </Button>
            )}
            <DeleteContractDialog
              title={tCommon('delete')}
              contract={contract}
              isActive={showDeleteDialog}
              deactivate={() => setShowDeleteDialog(false)}
            />
          </div>
          <div className="margin-left--3 stack-large">
            <div className="display--flex gap--3">
              <div className="flex--1 stack">
                <div className="stack-small">
                  <b>{tSiva('company.details.contract.overview.id')}</b>
                  <p>{contract.numericId}</p>
                </div>
                <div className="stack-small">
                  <b>{tCommon('phase')}</b>
                  <p>
                    {tSiva(`phase.${currentPhase?.sivaPhase || contract.phases.at(-1)?.sivaPhase}` as any, {
                      defaultValue: currentPhase?.sivaPhase || '',
                    })}
                  </p>
                </div>

                <div className="stack-small">
                  <b>{tCommon('industry')}</b>
                  <p>{contract.externalIndustry || tCommon('notSet')}</p>
                </div>
                <div className="stack-small">
                  <b>{tSiva('company.details.contract.overview.ideaName')}</b>
                  <p>{contract.name}</p>
                </div>
                <div className="stack-small">
                  <b>{tSiva('company.details.contract.overview.ideaSource')}</b>
                  <p>
                    {contract.ideaSource.length > 0
                      ? // @ts-expect-error IdeaSource is not well defined, so ts throws a hissy fit.
                        tSiva(`ideaSource.${contract.ideaSource}`)
                      : tSiva('company.details.contract.overview.ideaSource.none')}
                  </p>
                </div>
              </div>
              <div className="flex--1 stack">
                {contractAdmissionDate && (
                  <>
                    <div className="stack-small">
                      <b>{tSiva('company.details.contract.overview.admissionDate')}</b>
                      <p>
                        {formatDateToNOString(
                          new Date(contractAdmissionDate),
                          false,
                          '{date}.{month}.{year}',
                          true,
                        )}
                      </p>
                    </div>
                    <div className="stack-small">
                      <b>{tSiva('company.details.contract.overview.exitDate')}</b>
                      <p>
                        {contractEndDate
                          ? formatDateToNOString(
                              new Date(contractEndDate),
                              false,
                              '{date}.{month}.{year}',
                              true,
                            )
                          : tSiva('company.details.contract.overview.ongoingContract')}
                      </p>
                    </div>
                  </>
                )}

                {contractAdmissionDate && (
                  <>
                    <div className="stack-small">
                      <b>{tSiva('company.details.contract.overview.dispatchAdmissionForm')}</b>
                      <p>
                        {!!admissionFormDate &&
                          capitalizeFirstLetter(
                            formatDateToNOString(new Date(admissionFormDate), true, '{month} {year}', true),
                          )}
                      </p>
                    </div>
                    {!!enrouteFormDate && !(exitFormDate && enrouteFormDate > exitFormDate) && (
                      <>
                        <div className="stack-small">
                          <b>{tSiva('company.details.contract.overview.dispatchEnRouteForm')}</b>
                          <p>
                            {capitalizeFirstLetter(
                              formatDateToNOString(new Date(enrouteFormDate), true, '{month} {year}', true),
                            )}
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}

                {!!exitFormDate && (
                  <>
                    <div className="stack-small">
                      <b>{tSiva('company.details.contract.overview.dispatchExitForm')}</b>
                      <p>
                        {capitalizeFirstLetter(
                          formatDateToNOString(new Date(exitFormDate), true, '{month} {year}', true),
                        )}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="stack">
              <div className="stack-small">
                <b>{tCommon('contactPerson')}</b>
                <p>
                  {contract.contactFirstName} {contract.contactLastName}
                </p>
              </div>
              <div className="stack-small">
                <b>{tCommon('email')}</b>
                <p>{contract.contactEmail}</p>
              </div>
              {contract.municipality && currentPhase?.sivaPhase === SivaPhase.Idea && (
                <>
                  <div className="stack-small">
                    <b>{tCommon('municipality')}</b>
                    <p>{getMunicipalityNameByNumber(contract.municipality)}</p>
                  </div>
                </>
              )}
            </div>
            <div className="stack">
              <div className="stack-small">
                <b>{tCommon('advisor')}</b>
                <p>{contract.advisorName || tCommon('notSet')}</p>
              </div>
              <div className="stack-small">
                <b>{tCommon('note')}</b>
                <p>{contract.notes}</p>
              </div>
            </div>
            <ContractHistoric phases={contract.phases} />
          </div>
        </div>
      );
  }
};

export default CompanyContract;
