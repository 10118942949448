import { useState } from 'react';
import { useMutation } from 'react-query';
import { SivaGoalAccomplishmentService } from 'src/api/v2';

const useDownloadGoalOverviewReport = (clusterStatusId: string | undefined) => {
  const [service] = useState(new SivaGoalAccomplishmentService());

  const fetchReport = useMutation(async () => {
    return await service.getGoalAccomplishmentOverviewReport(clusterStatusId ?? '');
  });
  return { fetchReport };
};

export default useDownloadGoalOverviewReport;
