import React from 'react';
import { RouteObject } from 'react-router-dom';

import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';
import ExtractOverview from './pages/ExtractOverview';
import ExtractGoals from './pages/ExtractGoals';
import ExtractGoalAccomplishment from './pages/ExtractGoalAccomplishment';
import ExtractIdeasAndCompanies from './pages/ExtractIdeasAndCompanies';

const routes: RouteObject[] = [
  {
    path: '',
    index: true,
    element: <ExtractOverview />,
  },
  {
    path: ``,
    element: <ExtractOverview />,
  },
  {
    path: `/goals`,
    element: <ExtractGoals />,
  },
  {
    path: `/goalaccomplishment`,
    element: <ExtractGoalAccomplishment />,
  },
  {
    path: `/ideas-and-companies`,
    element: <ExtractIdeasAndCompanies />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default routes;
