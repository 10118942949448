import React, { useCallback, useState } from 'react';
import useCompanyContract from '../hooks/use-company-contract';
import { useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Select } from 'src/components/Form/Select';
import { Button, ButtonVariant, Notification, Theme } from '@in/component-library';
import Input from 'src/components/Form/Input';
import { formatDateToNOString } from 'src/utils/FormatValue';
import { capitalizeFirstLetter } from 'src/utils/string';
import TextArea from 'src/components/Form/TextArea';
import useToggle from 'src/hooks/use-toggle';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import ErrorSupportMessage from 'src/features/siva/components/ErrorSupportMessage';
import { requiredI18n } from 'src/utils/validation';
import { convertIdToNumber } from '../utils/idConversion';
import { SivaContractUpdateDto, SivaPhase } from 'src/api/v2';
import { bransjeOptions, ideaSourceOptions } from '../../constants';
import useSivaAdvisorOptions from '../../hooks/use-siva-advisor-options';
import '../styles/Contract.scss';
import { Link } from 'react-router-dom';
import { useSupportEmail } from 'src/hooks/use-support-email';
import ConnectCompany from './ConnectCompany';

type Props = {
  onCancel: () => void;
};

const EditContract: React.FC<Props> = ({ onCancel }) => {
  const { contractId } = useParams<PageParams>();
  const {
    contract,
    contractAdmissionDate,
    currentPhase,
    admissionFormDate,
    enrouteFormDate,
    exitFormDate,
    updateSivaContractMutation,
    company,
  } = useCompanyContract(convertIdToNumber(contractId));

  // Dette gjør at vi kan refetch rådgivere i et intervall, etter at bruker har trykt på linken for Tilgangsstyring
  const [refetchInterval, setRefetchInterval] = useState(0);
  const REFETCH_INTERVAL = 1000 * 15;
  const startRefetchInterval = useCallback(() => setRefetchInterval(REFETCH_INTERVAL), [REFETCH_INTERVAL]);
  const { advisorOptions } = useSivaAdvisorOptions({
    refetchInterval,
  });

  const [companyError, setCompanyError] = useState(false);
  const [orgNumber, setOrgNumber] = useState('');
  const [selectedCompanyName, setSelectedCompanyName] = useState('');

  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { t: tError } = useTranslation('error');
  const [updateError, setUpdateError] = useState(false);

  const { isActive, activate, deactivate } = useToggle(false);
  const { email, mailto } = useSupportEmail();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SivaContractUpdateDto>({
    shouldFocusError: true,
    defaultValues: {
      ...contract,
    },
  });

  const onSubmit = handleSubmit(async (updateDto: SivaContractUpdateDto) => {
    await updateSivaContractMutation
      .mutateAsync({
        ...updateDto,
        organizationNumber: orgNumber,
      })
      .then(() => {
        setUpdateError(false);
      })
      .catch(() => {
        setUpdateError(true);
      })
      .finally(() => {
        activate();
      });
  });

  return (
    <div className="width--60">
      <h2 className="margin-bottom--5">{tSiva('company.details.contract.editContract')}</h2>

      <form onSubmit={onSubmit} className="stack">
        <div>
          {currentPhase?.sivaPhase === SivaPhase.Idea ? (
            <h3>{tSiva('idea.description')}</h3>
          ) : (
            <h3>{tSiva('newCompany.contract.section.info.title')}</h3>
          )}

          {!company && (
            <div>
              <span className="font-weight--bold">{tSiva('contract.edit.connectCompany')}</span>
              {currentPhase && (
                <ConnectCompany
                  phase={currentPhase.sivaPhase}
                  selectedCompanyName={selectedCompanyName}
                  orgNumber={orgNumber}
                  setOrgNumber={setOrgNumber}
                  setSelectedCompanyName={setSelectedCompanyName}
                  setCompanyError={setCompanyError}
                  showNoCompanyCheckbox={true}
                />
              )}
            </div>
          )}

          <div>
            <span className="font-weight--bold">{tCommon('phase')}</span>
          </div>
          <div>
            {tSiva(`phase.${currentPhase?.sivaPhase}` as any, {
              defaultValue: currentPhase?.sivaPhase || '',
            })}
          </div>
        </div>
        <div>
          <Select
            label={`${tCommon('industry')} ${currentPhase?.sivaPhase === SivaPhase.Idea ? '' : '*'}`}
            {...register('externalIndustry', {
              required: currentPhase?.sivaPhase === SivaPhase.Idea ? undefined : requiredI18n(tError),
            })}
            error={errors?.externalIndustry?.message}
            options={bransjeOptions}
          />
        </div>
        <div>
          <Input
            label={`${tSiva('company.details.contract.overview.ideaName')} *`}
            {...register('name', { required: requiredI18n(tError) })}
            error={errors?.name?.message}
          />
        </div>
        <div>
          <Select
            label={`${tSiva('company.details.contract.overview.ideaSource')} *`}
            {...register('ideaSource', {
              required: requiredI18n(tError),
            })}
            error={errors?.ideaSource?.message}
            options={ideaSourceOptions}
          />
        </div>
        {contract?.phases.at(-1)?.sivaPhase !== SivaPhase.Idea && (
          <>
            <div className="margin-top--6 ">
              <Notification type="generic" fullWidth>
                {`${tSiva('company.details.contract.editContract.admissionNote')} `}
                <a className="siva-contract-overview__back-link" href={mailto} title="support@siva.no">
                  {email}
                </a>
              </Notification>
            </div>
            <div>
              <div>
                <span className="font-weight--bold">
                  {tSiva('company.details.contract.overview.admissionDate')}
                </span>
              </div>
              <div>
                {contractAdmissionDate &&
                  formatDateToNOString(new Date(contractAdmissionDate), false, '{date}.{month}.{year}', true)}
              </div>
            </div>
            <div>
              <div>
                <span className="font-weight--bold">
                  {tSiva('company.details.contract.overview.dispatchAdmissionForm')}
                </span>
              </div>
              <div>
                {!!admissionFormDate &&
                  capitalizeFirstLetter(
                    formatDateToNOString(new Date(admissionFormDate), true, '{month} {year}', true),
                  )}
              </div>
            </div>
            <div>
              <div>
                <span className="font-weight--bold">
                  {tSiva('company.details.contract.overview.dispatchEnRouteForm')}
                </span>
              </div>
              <div>
                {!!enrouteFormDate &&
                  capitalizeFirstLetter(
                    formatDateToNOString(new Date(enrouteFormDate), true, '{month} {year}', true),
                  )}
              </div>
            </div>
          </>
        )}
        {!!exitFormDate && (
          <div>
            <div>
              <span className="font-weight--bold">
                {tSiva('company.details.contract.overview.dispatchExitForm')}
              </span>
            </div>
            <div>
              {capitalizeFirstLetter(
                formatDateToNOString(new Date(exitFormDate), true, '{month} {year}', true),
              )}
            </div>
          </div>
        )}
        <div className="margin-top--6">
          <h3>{tCommon('contact')}</h3>
          <p>{tSiva('newCompany.contract.section.contact.description')}</p>
        </div>
        <div className="display--flex gap--4 justify-content--space-between">
          <div>
            <Input
              label={`${tCommon('firstName')} *`}
              {...register('contactFirstName', { required: requiredI18n(tError) })}
              error={errors?.name?.message}
            />
          </div>
          <div>
            <Input
              label={`${tCommon('lastName')} *`}
              {...register('contactLastName', { required: requiredI18n(tError) })}
              error={errors?.name?.message}
            />
          </div>
        </div>
        <div>
          <Input
            label={`${tCommon('email')} *`}
            {...register('contactEmail', { required: requiredI18n(tError) })}
            error={errors?.name?.message}
          />
        </div>
        <div className="margin-top--6 ">
          <h3>{tSiva('newCompany.contract.section.followUp.title')}</h3>
          <Select
            label={`${tCommon('advisor')} *`}
            {...register('advisorId', {
              required: requiredI18n(tError),
            })}
            error={errors?.advisorId?.message}
            options={advisorOptions}
          />
          <p className="margin-top--0 margin-bottom--4">
            {tSiva('newCompany.contract.form.advisorId.link.text')}{' '}
            <Link
              to={`/actor-profile/settings/access`}
              target="_blank"
              onClick={() => {
                startRefetchInterval();
              }}
            >
              <span>{tCommon('accessControl')}</span>
            </Link>
            .
          </p>
        </div>
        <div>
          <TextArea label={tCommon('note')} {...register('notes')} maxCount={500} maxLength={500} rows={4} />
        </div>
        <div className="display--flex gap--1 justify-content--flex-start margin-top--7">
          <Button
            theme={Theme.Neutral}
            type="submit"
            isLoading={updateSivaContractMutation.isLoading}
            disabled={updateSivaContractMutation.isLoading || companyError}
          >
            {tCommon('save')}
          </Button>
          <Button
            theme={Theme.Neutral}
            variant={ButtonVariant.Outlined}
            type="button"
            onClick={onCancel}
            isLoading={updateSivaContractMutation.isLoading}
            disabled={updateSivaContractMutation.isLoading}
          >
            {tCommon('cancel')}
          </Button>
        </div>
      </form>
      <DialogContainer
        title={
          updateError
            ? tSiva('company.details.contract.editContract.errormodal.header')
            : tCommon('confirmation')
        }
        show={isActive}
        onClose={deactivate}
      >
        {!updateError && <div>{tSiva('company.details.contract.editContract.successmodal.text')}</div>}
        {!!updateError && <ErrorSupportMessage />}
        <Button className="margin-top--6" theme={Theme.Neutral} type="button" onClick={() => deactivate()}>
          {tCommon('close')}
        </Button>
      </DialogContainer>
    </div>
  );
};

export default EditContract;
