import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import CompanyInfo from './pages/CompanyInfo';
import Contracts from './pages/Contracts';
import ESAReporting from './pages/ESAReporting';
import GoalAchievement from './pages/GoalAchievement';
import ContractOverview from './pages/ContractOverview';
import EsaYearSelect from './pages/EsaYearSelect';

export const COMPANY_INFO = 'info';
export const COMPANY_CONTRACTS = 'contracts';
export const COMPANY_ESA_REPORTING = 'esa-reporting';
export const COMPANY_GOAL_ACHIEVEMENT = 'goal-achievement';

export const COMPANY_DETAILS = (baseRoute: string) =>
  ({
    INDEX: (contractId: string) => `${baseRoute}/${contractId}` as const,
    INFO: (companyId: string) => `${baseRoute}/${companyId}/${COMPANY_INFO}` as const,
    CONTRACTS: (companyId: string, contractId?: string) =>
      `${baseRoute}/${companyId}/${COMPANY_CONTRACTS}${contractId ? `/${contractId}` : '/'}` as const,
    ESA_REPORTING: (companyId: string) => `${baseRoute}/${companyId}/${COMPANY_ESA_REPORTING}` as const,
    GOAL_ACHIEVEMENT: (companyId: string) => `${baseRoute}/${companyId}/${COMPANY_GOAL_ACHIEVEMENT}` as const,
  }) as const;

const routes: RouteObject[] = [
  {
    path: `${COMPANY_INFO}`,
    element: <CompanyInfo />,
  },
  {
    path: '',
    element: <ContractOverview />,
  },
  {
    path: `${COMPANY_CONTRACTS}/*`,
    children: [
      {
        path: '',
        index: true,
        element: <Contracts />,
      },
    ],
  },
  {
    path: `${COMPANY_ESA_REPORTING}`,
    element: <EsaYearSelect />,
  },
  {
    path: `${COMPANY_GOAL_ACHIEVEMENT}`,
    element: <GoalAchievement />,
  },
  {
    path: '*',
    element: <Navigate to={`${COMPANY_INFO}`} replace />,
  },
];

export default routes;
