import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTableV3 from 'src/components/DataTableV3';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import useDocumentTitle from 'src/hooks/use-document-title';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { extractGoalsTableColumns } from '../constants/extract-goals-table-columns';
import { ExtractGoalTableType } from '../types/ExtractGoalTableType';
import { StaticIdentifiers } from 'src/constants/cluster-status';
import { getExpectedValueInDataset } from '../utils/table-utils';
import { Button, FaIcon, Select } from '@in/component-library';
import { useSearchParams } from 'react-router-dom';
import { CreateTargetNumberSpecialExport } from '../utils/extract-target-number-special-export';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { getSIVAYearList } from 'src/features/siva-admin/budget/utils/getYearList';
import { useSivaGoalsDetailsList } from 'src/features/siva-admin/hooks/use-siva-goals-details-list';

const pageSearchParams = {
  year: 'year',
  actorType: 'actorType',
};

const ExtractGoals: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { t: tSiva } = useTranslation('siva');
  const { t: tTableTitles } = useTranslation('tableTitles');
  const { documentTitle } = useDocumentTitle(tPageTitles('sivaReporting'));
  const [searchParams, setSearchParams] = useSearchParams();
  const years = getSIVAYearList();

  const startYear = years?.[0] || new Date().getFullYear() + 1;

  const year = +(searchParams.get(pageSearchParams.year) || startYear);
  const actorType = searchParams.get(pageSearchParams.actorType) || StaticIdentifiers.SivaBusinessGarden;

  const columns = extractGoalsTableColumns(year);

  const { data, isFetching } = useSivaGoalsDetailsList(year);
  const { data: prev1YearData, isFetching: prev1YearDataFetching } = useSivaGoalsDetailsList(year - 1);
  const { data: prev2YearData, isFetching: prev2YearDataFetching } = useSivaGoalsDetailsList(year - 2);
  const { data: prev3YearData, isFetching: prev3YearDataFetching } = useSivaGoalsDetailsList(year - 3);

  const { dataThisYear, dataPrev1Year, dataPrev2Year, dataPrev3Year } = React.useMemo(() => {
    return {
      dataThisYear: data?.filter((actorGoalDetails) => actorGoalDetails.clusterStatusId === actorType) || [],
      dataPrev1Year:
        prev1YearData?.filter((actorGoalDetails) => actorGoalDetails.clusterStatusId === actorType) || [],
      dataPrev2Year:
        prev2YearData?.filter((actorGoalDetails) => actorGoalDetails.clusterStatusId === actorType) || [],
      dataPrev3Year:
        prev3YearData?.filter((actorGoalDetails) => actorGoalDetails.clusterStatusId === actorType) || [],
    };
  }, [actorType, data, prev1YearData, prev2YearData, prev3YearData]);

  const tableData = React.useMemo(() => {
    if (!data) {
      return [] as ExtractGoalTableType[];
    }

    const rows: ExtractGoalTableType[] = [];

    dataThisYear.forEach((goalDetails) => {
      goalDetails.goalAccomplishments.forEach((goalAccomplishment) => {
        rows.push({
          id: goalAccomplishment.id,
          actorName: goalDetails.clusterName,
          goalName: goalAccomplishment.goalDetail.name || '',
          thisYearNumber: goalAccomplishment.expectedValue,
          previous1YearNumber: getExpectedValueInDataset(
            goalDetails.clusterId,
            goalAccomplishment.goalDetail.id,
            dataPrev1Year,
          ),
          previous2YearNumber: getExpectedValueInDataset(
            goalDetails.clusterId,
            goalAccomplishment.goalDetail.id,
            dataPrev2Year,
          ),
          previous3YearNumber: getExpectedValueInDataset(
            goalDetails.clusterId,
            goalAccomplishment.goalDetail.id,
            dataPrev3Year,
          ),
        });
      });
    });

    return rows as ExtractGoalTableType[];
  }, [data, dataThisYear, dataPrev1Year, dataPrev2Year, dataPrev3Year]);

  const onSpecialExcelDownload = () => {
    const record = {};

    record[year] = dataThisYear;
    record[year - 1] = dataPrev1Year;
    record[year - 2] = dataPrev2Year;
    record[year - 3] = dataPrev3Year;

    CreateTargetNumberSpecialExport(
      actorType === StaticIdentifiers.SivaBusinessGarden ? 'Næringshage' : 'Inkubator',
      record,
    );
  };

  const isDataLoading = isFetching && prev1YearDataFetching && prev2YearDataFetching && prev3YearDataFetching;

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <h1>{tSiva('extract.targetNumber.header')}</h1>
        <div className="margin-top--6 margin-bottom--3 display--flex gap--3 align-items--center">
          <Select
            name={tCommon('type')}
            label={tCommon('type')}
            options={[
              {
                text: tCommon('businessGarden'),
                value: StaticIdentifiers.SivaBusinessGarden,
              },
              {
                text: tCommon('incubator'),
                value: StaticIdentifiers.SivaIncubator,
              },
            ]}
            value={actorType}
            onChange={(actorType) =>
              setSearchParams(
                (prevValue) => {
                  const year = prevValue.get(pageSearchParams.year) || '';
                  return { actorType, year };
                },
                { replace: true },
              )
            }
          />
          <Select
            name={tCommon('year')}
            label={tCommon('year')}
            options={years.map((year) => ({
              text: year.toString(),
              value: year.toString(),
            }))}
            value={year.toString()}
            onChange={(year) =>
              setSearchParams(
                (prevValue) => {
                  const actorType = prevValue.get(pageSearchParams.actorType) || '';
                  return { actorType, year };
                },
                { replace: true },
              )
            }
          />
          <Button onClick={onSpecialExcelDownload} disabled={isDataLoading} variant="link">
            <FaIcon
              className={'margin-right--1'}
              icon={icon({ prefix: 'fal', iconName: 'file-export' }) as IconDefinition}
              size="1x"
            />
            {`${tSiva('extract.targetNumber.exportButton')} ${
              actorType === StaticIdentifiers.SivaBusinessGarden
                ? tCommon('businessGarden').toLowerCase()
                : tCommon('incubator').toLowerCase()
            }`}
          </Button>
        </div>
        <div>
          {isDataLoading && <LoadingPage />}
          {!isDataLoading && (
            <DataTableV3
              id={DataTableV3Ids.SivaAdmin.ExtractGoals}
              disableSelection={false}
              disableGlobalFilter={false}
              data={tableData}
              columns={columns}
              translationFn={(key: string) => tTableTitles(key as any)}
              texts={{
                globalFilterPlaceholder: tCommon('search'),
                rowsPerPage: tCommon('rowsPerPage'),
                of: tCommon('of'),
              }}
              pageSizeOptions={[5, 10, 25, 100]}
            />
          )}
        </div>
      </Page>
    </DocumentTitle>
  );
};

export default ExtractGoals;
