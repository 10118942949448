import React from 'react';
import { formatDateToNOString } from '../../../../utils/FormatValue';
import { createColumnHelper } from '@tanstack/react-table';
import { SivaContractOverviewDto, SivaPhase } from '../../../../api/v2';
import { MessageType, Tag } from '@in/component-library';
import { padNumericId } from '../utils';
import { Link } from 'react-router-dom';
import SivaPhaseTag from '../components/SivaPhaseTag';
import { ROUTES } from 'src/navigation';
import i18next from 'i18next';
import { toIsoDateString } from '../../../../utils/date';

const columnHelper = createColumnHelper<SivaContractOverviewDto>();

export const advisorColumnAccessor = (row: SivaContractOverviewDto) =>
  `${row.advisorFirstName ?? ''} ${row.advisorLastName ?? ''}`.trim() ||
  `(${i18next.t('common:notRegistered')})`;
export const advisorColumn = columnHelper.accessor(advisorColumnAccessor, {
  id: 'advisor',
  minSize: 170,
});
export const numericIdColumn = columnHelper.accessor((row) => padNumericId(row.numericId), {
  id: 'numericId',
});
export const registrationDateColumn = columnHelper.accessor((row) => row.fromDate, {
  id: 'registrationDate',
  sortingFn: 'datetime',
  minSize: 95,
  size: 95,
  cell: (info) => `${formatDateToNOString(info.getValue(), false, '{date}.{month}.{year}', true)}`,
});
//Ja, dette er samme data som over. De vil bare ha et annet navn på den, og litt egen behandling.
//Dette er enkleste måte
export const endDateColumn = columnHelper.accessor((row) => row.fromDate, {
  id: 'sivaEndDate',
  sortingFn: 'datetime',
  minSize: 95,
  size: 95,
  cell: (info) => `${formatDateToNOString(info.getValue(), false, '{date}.{month}.{year}', true)}`,
});
export const phaseColumn = columnHelper.accessor((row) => row.phase, {
  id: 'phase',
  cell: (info) => <SivaPhaseTag phase={info.getValue()} />,
});
export const admissionDateColumn = columnHelper.accessor((row) => toIsoDateString(row.admissionDate), {
  id: 'admissionDate',
  cell: (info) => {
    const admissionDate = info.getValue();
    if (!admissionDate) return '';
    const [year, month, day] = admissionDate.split('-');
    return `${day}.${month}.${year}`;
  },
});
export const goalReportingColumn = columnHelper.accessor((row) => row.goalAccomplishmentDelivered, {
  id: 'goalAccomplishment',
  size: 120,
  minSize: 120,
  cell: (info) => {
    const isDelivered = info.getValue();
    return (
      <Tag type={isDelivered ? MessageType.Success : MessageType.Generic}>
        {isDelivered ? i18next.t('common:completed') : i18next.t('common:notCompleted')}
      </Tag>
    );
  },
});
export const ideaNameColumn = columnHelper.accessor((row) => row.name || row.companyName, {
  id: 'name',
  cell: (info) => <ContractLink numericId={info.row.original.numericId} text={info.getValue()} />,
});
export const companyNameOrIdeaNameColumn = columnHelper.accessor((row) => row.companyName ?? row.name, {
  id: 'name',
  size: 200,
  minSize: 200,
  cell: (info) => <ContractLink numericId={info.row.original.numericId} text={info.getValue()} />,
});
export const statusColumn = columnHelper.accessor((row) => row.phase, {
  id: 'status',
  cell: (info) =>
    info.getValue() == SivaPhase.Alumni ? (
      <Tag type={MessageType.Generic}>Inaktiv</Tag>
    ) : (
      <Tag type={MessageType.Success}>Aktiv</Tag>
    ),
});
const ContractLink: React.FC<{
  numericId: number | string;
  text: React.ReactNode;
}> = ({ numericId, text }) => {
  const url = ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.INDEX(`${numericId}`);

  return <Link to={url}>{text}</Link>;
};
export const selfDeclarationColumn = columnHelper.accessor((row) => row.selfDeclarationDelivered, {
  id: 'selfDeclaration',
  size: 120,
  minSize: 120,
  cell: (info) => {
    const isDelivered = info.getValue();
    return (
      <Tag type={isDelivered ? MessageType.Success : MessageType.Generic}>
        {isDelivered ? i18next.t('common:delivered') : i18next.t('common:notDelivered')}
      </Tag>
    );
  },
});
export const esaColumn = columnHelper.accessor((row) => row.esaReportingDone, {
  id: 'esa',
  size: 150,
  minSize: 150,
  cell: (info) => {
    const isRegistered = info.getValue();
    return (
      <Tag type={isRegistered ? MessageType.Success : MessageType.Generic}>
        {isRegistered ? i18next.t('common:registered') : i18next.t('common:notRegistered')}
      </Tag>
    );
  },
});
