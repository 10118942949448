import { useState } from 'react';
import { useQuery } from 'react-query';
import { SivaManagementService } from 'src/api/v2';
import { SIVA_GOALS_DETAILS_LIST_KEY } from 'src/constants/hook-keys';
import { useAuth } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';

export function useSivaGoalsDetailsList(year: number | undefined) {
  const { cluster } = useCluster();
  const { checkAccess } = useAuth();
  const { read } = checkAccess('SivaAdmin');

  const [service] = useState(() => new SivaManagementService());

  const { data, ...rest } = useQuery({
    queryKey: [SIVA_GOALS_DETAILS_LIST_KEY, year],
    queryFn: () => service.getSivaGoalsDetailsList(year!),
    enabled: !!cluster && !!year && !isNaN(year) && read,
  });

  return {
    data: data || [],

    ...rest,
  };
}
