import React, { useMemo, useState } from 'react';
import { OneWayInOpportunityStatusDto, OneWayInStatus } from 'src/api/v2';
import Table from 'src/components/Table';
import HistoryTableSection, { HistoryTableSectionProps } from './HistoryTableSection';
import { PageLoader } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import useOneWayIn from '../../../hooks/use-one-way-in';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import HistoryViewEmailContent from './HistoryViewEmailContent';
import { formatDateToNOString } from 'src/utils/FormatValue';
import RecommendServiceMailCustomer from '../../../components/recommend-service/RecommendServiceMailCustomer';
import { CustomerMailRecommendedServices } from '../../../models/OneWayInModels';
import HistoryTableINSection from './HistoryTableINSection';
import { sanitizeMinSideEmail } from '../../../utils/htmlTextConverter';
import { MINSIDE_REFERRALPARTY_FIRST_LINE_FILTER } from 'src/features/one-way-in/constants/firstLineReferralParty';

import './OneWayInHistory.scss';
import useOneWayInVMA from 'src/features/one-way-in/hooks/use-one-way-in-vma';

type Props = {
  data: OneWayInOpportunityStatusDto[];
  isLoading?: boolean;
  formId: string;
};

export interface EmailViewContent {
  title: string;
  from: string;
  to: string;
  date: string;
  text: string;
  recommendedMail: boolean;
  userName?: string;
  actorName?: string;
  recommendedServices?: CustomerMailRecommendedServices[];
}

const OneWayInHistoryV2: React.FC<Props> = ({ data, isLoading, formId }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const { oneWayInOpportunityFormQuery, onewWayInOpportunityFormINResponseMailQuery } = useOneWayIn(formId);
  const { oneWayInAllVMAsQuery } = useOneWayInVMA();

  const [emailViewActive, setEmailViewActive] = useState(false);

  const referredActors = useMemo(() => {
    const referredActors = oneWayInOpportunityFormQuery.data?.opportunity.originalRecipients || [];
    const originalVMAs = oneWayInAllVMAsQuery.data || [];

    const filteredOriginalVMAs = originalVMAs.filter((vma) => {
      if (vma.id === MINSIDE_REFERRALPARTY_FIRST_LINE_FILTER) return true;
      return vma.isBusinessSupportOrganization;
    });

    return filteredOriginalVMAs.filter((vma) => referredActors.some((actor) => actor === vma.id));
  }, [oneWayInOpportunityFormQuery.data, oneWayInAllVMAsQuery.data]);

  const [emailContent, setEmailContent] = React.useState<EmailViewContent>({
    title: '',
    from: '',
    to: '',
    date: '',
    text: '',
    recommendedMail: false,
  });

  const openEmailView = (content: EmailViewContent) => {
    if (!content) return;

    setEmailContent(content);
    setEmailViewActive(true);
  };

  const openINEmailView = () => {
    if (!onewWayInOpportunityFormINResponseMailQuery.data) return;

    setEmailContent({
      title: onewWayInOpportunityFormINResponseMailQuery.data.subject || '',
      from: '',
      to: '',
      date: formatDateToNOString(
        onewWayInOpportunityFormINResponseMailQuery.data.createdOn || new Date(),
        false,
        '{date}.{month}.{year}',
        true,
      ),
      text: onewWayInOpportunityFormINResponseMailQuery.data.body || '',
      recommendedMail: false,
    });

    setEmailViewActive(true);
  };

  const history = useMemo(() => {
    const record: Record<string, OneWayInOpportunityStatusDto[]> = {};

    data.forEach((item) => {
      // Ignore the received status, and new status.
      if (item.status === OneWayInStatus.Received || item.status === OneWayInStatus.New) return;

      const _date = new Date(item.createdOn.toString());
      const date = _date.getDate();
      const month = _date.getMonth();
      const year = _date.getFullYear();

      const key = new Date(year, month, date).toISOString();

      if (!record[key]) {
        record[key] = [];
      }

      record[key].push(item);
    });

    const keys = Object.keys(record)
      // dette sorterer fra eldst til nyest
      .sort()
      // så vi reverserer for å få nyeste først
      .reverse();

    const result = keys.map((key) => {
      return {
        date: new Date(key),
        data: [...record[key]].sort((a, b) => {
          const A = new Date(a.createdOn.toString());
          const B = new Date(b.createdOn.toString());

          // A - B = eldst først
          // så vi reverserer for å få nyeste først
          return B.getTime() - A.getTime();
        }),
      } as HistoryTableSectionProps;
    });

    return result;
  }, [data]);

  if (isLoading) {
    return <PageLoader aria-label={tCommon('loadingData')} />;
  }

  return (
    <div className="one-way-in-history__page-div">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header style={{ width: '56px' }}></Table.Header>
            <Table.Header style={{ width: '300px' }}>{tOneWayIn('log.table.column.activity')}</Table.Header>
            <Table.Header style={{ width: '300px' }}>{tOneWayIn('log.table.column.actor')}</Table.Header>
            <Table.Header>{tOneWayIn('log.table.column.behandler')}</Table.Header>
            <Table.Header>{tOneWayIn('log.table.column.description')}</Table.Header>
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {history.map(({ data, date }) => (
            <HistoryTableSection
              key={date.toISOString()}
              data={data}
              date={date}
              openEmailView={openEmailView}
            />
          ))}
          <HistoryTableINSection
            deliverDate={
              onewWayInOpportunityFormINResponseMailQuery.data?.createdOn
                ? formatDateToNOString(onewWayInOpportunityFormINResponseMailQuery.data?.createdOn)
                : ''
            }
            receivedDate={oneWayInOpportunityFormQuery.data?.form.innsendtDato || ''}
            openEmailView={openINEmailView}
            hasEmail={!!onewWayInOpportunityFormINResponseMailQuery.data}
            referredActors={referredActors}
          />
        </Table.Body>
      </Table>
      <DialogContainer
        title={emailContent.title}
        show={emailViewActive}
        onClose={() => setEmailViewActive(false)}
      >
        <HistoryViewEmailContent
          from={emailContent.from}
          to={emailContent.to}
          showFromAndTo={emailContent.from !== '' || emailContent.to !== ''}
          date={emailContent.date}
          content={
            emailContent.recommendedMail ? (
              <RecommendServiceMailCustomer
                customerName={emailContent.to}
                note={emailContent.text}
                userName={emailContent.userName || ''}
                actorName={emailContent.actorName || ''}
                recommendedServices={emailContent.recommendedServices || []}
                textView
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: sanitizeMinSideEmail(emailContent.text) }} />
            )
          }
          onClose={() => setEmailViewActive(false)}
        />
      </DialogContainer>
    </div>
  );
};

export default OneWayInHistoryV2;
