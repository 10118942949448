import { useMemo } from 'react';

const useYearList = (yearLimit: number) => {
  return useMemo(() => {
    let year = new Date().getFullYear();
    const yearArray: number[] = [];
    while (year >= yearLimit) {
      yearArray.push(year);
      year--;
    }
    return yearArray;
  }, [yearLimit]);
};

export default useYearList;
