import { Button } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationCheckBox from './NavigationCheckbox';
import { useParams, useSearchParams } from 'react-router-dom';
import { OneWayInServiceProviderDto } from 'src/api/v2';
import useSiteimprove from 'src/hooks/use-siteimprove';
import { SITEIMPROVE_DECLARATIONS } from 'src/features/tracking/constants';
import { PageParams } from '../../types/PageParams';

type Props = {
  step: number;
  numberOfSelectedRows: number;
  archiveReferral: boolean;
  setArchiveReferral: (value: React.SetStateAction<boolean>) => void;
  stepCount: number;
  handleSendReferral: () => void;
  actorMessageEmpty: boolean;
  customerMessageEmpty: boolean;
  customerEmailSelection: boolean;
  handleStepChange: (prev: number, next: number) => void;
};

const NavigationBar: React.FC<Props> = ({
  step,
  numberOfSelectedRows,
  archiveReferral,
  setArchiveReferral,
  stepCount,
  handleSendReferral,
  actorMessageEmpty,
  customerMessageEmpty,
  customerEmailSelection,
  handleStepChange,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [, setSearchParams] = useSearchParams();
  const { formId } = useParams<PageParams>();
  const siteimprove = useSiteimprove();

  const NavigationPrimaryButton = () => {
    const handlePrimaryButtonClick = () => {
      if (step === 2) {
        handleSendReferral();
        return;
      }

      siteimprove.log(
        SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
        step === 1
          ? SITEIMPROVE_DECLARATIONS.ACTIONS.GO_TO_PREVIEW
          : SITEIMPROVE_DECLARATIONS.ACTIONS.START_REFERRAL,
        formId,
      );

      handleStepChange(step, step + 1);
    };

    return (
      <Button
        className={step === 0 ? 'font-weight--bold' : ''}
        disabled={
          step === 0
            ? numberOfSelectedRows === 0
            : step === 1
              ? actorMessageEmpty || (customerEmailSelection && customerMessageEmpty)
              : false
        }
        onClick={handlePrimaryButtonClick}
        iconName={step === 0 ? 'arrow-right' : null}
        iconPosition={step === 0 ? 'right' : null}
      >
        {step === 0 && tOneWayIn('referral.navigationBar.step1.primaryButtonText')}
        {step === 1 && tOneWayIn('referral.navigationBar.step2.primaryButtonText')}
        {step === 2 && tOneWayIn('referral.navigationBar.step3.primaryButtonText')}
      </Button>
    );
  };

  const NavigationSecondayButton = () => {
    return (
      <Button
        onClick={() => {
          siteimprove.log(
            SITEIMPROVE_DECLARATIONS.CATEGORIES.FORWARDOPPORTUNITY,
            step === 1
              ? SITEIMPROVE_DECLARATIONS.ACTIONS.BACK_TO_CHOOSE_ACTOR
              : SITEIMPROVE_DECLARATIONS.ACTIONS.BACK_TO_WRITE_REFERRAL,
            formId,
          );

          handleStepChange(step, step - 1);
        }}
        variant="outlined"
        theme="neutral"
      >
        {tOneWayIn('referral.navigationBar.step2.secondaryButtonText')}
      </Button>
    );
  };

  const LeftSection = () => {
    return (
      <div className="display--flex flex-direction--column gap--1">
        <span className="font-weight--bold">{`${tOneWayIn('referral.navigationBar.step')} ${step + 1} ${tOneWayIn('referral.navigationBar.of')} ${stepCount}`}</span>
        <div className="display--flex flex-direction--row padding--0 gap--1">
          {step === 0 ? (
            <span className="font-size--medium">{`${
              numberOfSelectedRows === 0
                ? `${tOneWayIn('referral.navigationBar.noActorsSelected')}`
                : numberOfSelectedRows === 1
                  ? `${numberOfSelectedRows} ${tOneWayIn('details.Referral.Table.RowSelectedText')}`
                  : `${numberOfSelectedRows} ${tOneWayIn('details.Referral.Table.RowsSelectedText')}`
            }`}</span>
          ) : (
            <>
              <NavigationCheckBox archiveReferral={archiveReferral} setArchiveReferral={setArchiveReferral} />
              <span className="font-size--medium">
                {tOneWayIn('referral.navigationBar.archiveReferralOptionText')}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="navigation-bar__container">
      <div className="navigation-bar__content">
        <LeftSection />
        <div className="display--flex flex-direction--row gap--1">
          {step !== 0 && <NavigationSecondayButton />}
          <NavigationPrimaryButton />
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
