import React, { useMemo, useState } from 'react';
import useOneWayInServiceProviders from '../../hooks/use-one-way-in-service-providers';
import useGeonorge from '../../hooks/use-geonorge';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from 'src/hooks/use-document-title';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  DateInput,
  FaIcon,
  FaIcons,
  InputField,
  Notification,
  Tag,
} from '@in/component-library';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import '../../styles/OneWayInStyle.scss';
import {
  OneWayInFeedbackDto,
  OneWayInFormCollection,
  OneWayInServiceProviderDto,
  SkjemaInfo,
} from 'src/api/v2';
import { Select } from 'src/components/Form/Select';
import OneWayInReviewListItem from './OneWayInReviewListItem';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';
import { Counties } from '../../constants/counties';
import { MINSIDE_REFERRALPARTY_FIRST_LINE_FILTER } from '../../constants/firstLineReferralParty';

import { ONE_WAY_IN } from '../..';

import { OneWayInListDto as BaseOneWayInListDto } from '../../types/oneWayInList';

type OrderFromOldToNew = { init: boolean; set: (prevValue: boolean) => void };

type Props = {
  listQuery: UseInfiniteQueryResult<OneWayInFormCollection, unknown>;
  feedbackListQuery: UseQueryResult<OneWayInFeedbackDto[], unknown>;
  fromList: string[];
  countyView?: boolean;
  setFromDate?: (froMDate?: Date) => void;
  setToDate?: (toDate?: Date) => void;
  orderFromOldToNew?: OrderFromOldToNew;
};

type ResolvedObject = { opportunityId: string; resolved: boolean; comment?: string };

type OneWayInListDto = BaseOneWayInListDto & {
  serviceProviderName: string;
  resolved?: ResolvedObject;
};

type FeedbackResolvedFilter = 'missing' | 'correct' | 'incorrect' | 'notset';

const OneWayInReviewOpportunities: React.FC<Props> = ({
  listQuery,
  feedbackListQuery,
  fromList,
  countyView = false,
  setFromDate,
  setToDate,
  orderFromOldToNew,
}) => {
  const { serviceProviders } = useOneWayInServiceProviders();
  const { getMunicipalityNameByNumber } = useGeonorge();
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { t: tCommon } = useTranslation();

  const [searchQuery, setSearchQuery] = useState('');
  const [municipalityFilter, setMunicipalityFilter] = useState('');
  const [feedbackStatusFilter, setFeedbackStatusFilter] = useState<FeedbackResolvedFilter>('notset');

  const { documentTitle } = useDocumentTitle(tOneWayIn('opportunities'));

  const feedbackFilterOptions = [
    {
      text: tCommon('none'),
      value: 'notset',
    },
    {
      text: tOneWayIn('feedbackFilter.Missing'),
      value: 'missing',
    },
    {
      text: tOneWayIn('feedbackFilter.Correct'),
      value: 'correct',
    },
    {
      text: tOneWayIn('feedbackFilter.Incorrect'),
      value: 'incorrect',
    },
  ];

  const resolvedList = useMemo(() => {
    if (!feedbackListQuery.data) {
      return [] as ResolvedObject[];
    }

    return feedbackListQuery.data.map((feedback) => ({
      opportunityId: feedback.oneWayInOpportunityD365Id,
      resolved: feedback.wasCorrectlyReferred,
      comment: feedback.comment,
    }));
    // dataUpdatedAt is to ensure we are updating. May be unnecessary but leaving it here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackListQuery.data, feedbackListQuery.dataUpdatedAt]);

  const { opportunityList, municipalityOptions, total, notReviewed, sentCorrectly, sentIncorrectly } =
    useMemo(() => {
      const getServiceProviderName = (municipalityNumber: string) => {
        const serviceProvider = serviceProviders?.filter((sp) =>
          sp.municipalities.includes(municipalityNumber),
        );
        return serviceProvider;
      };

      const getCombinedProviderNames = (opportunity: SkjemaInfo) => {
        if (!opportunity.henvisningsPartnere) {
          return undefined;
        }

        const firstLineServiceProviders: OneWayInServiceProviderDto[] = [];

        if (
          opportunity.henvisningsPartnere.some(
            (partner) => partner.id === MINSIDE_REFERRALPARTY_FIRST_LINE_FILTER,
          )
        ) {
          const firstLineProviders = getServiceProviderName(opportunity.prosjektKommune || '');
          if (firstLineProviders) {
            firstLineServiceProviders.push(...firstLineProviders);
          }
        }

        const otherProviders = opportunity.henvisningsPartnere.map((p) => p.navn);

        const combinedProviders = [...firstLineServiceProviders.map((sp) => sp.name), ...otherProviders];

        const uniqueProviders = [...new Set(combinedProviders)];
        return uniqueProviders.join(', ');
      };

      if (!listQuery.data?.pages) {
        return {
          opportunityList: [] as (OneWayInListDto & {
            municipality: string;
            county: string | undefined;
            serviceProviderName: string | undefined;
            resolved: ResolvedObject | undefined;
          })[],
          municipalityOptions: [] as { text: string; value: string }[],
          total: 0,
          notReviewed: 0,
          sentCorrectly: 0,
          sentIncorrectly: 0,
        };
      }

      let list = listQuery.data?.pages
        .flatMap((data) => data.forms)
        .map((opportunity) => ({
          ...opportunity,
          municipality:
            getMunicipalityNameByNumber(opportunity.prosjektKommune || '') ?? opportunity.prosjektKommune,
          county:
            Counties.find((county) =>
              county.municipalities.some((municipality) => municipality === opportunity.prosjektKommune),
            )?.countyName || undefined,
          serviceProviderName: getCombinedProviderNames(opportunity),
          resolved: resolvedList.find((resolved) => resolved.opportunityId === opportunity.id) || undefined,
        }));

      const resolvedObjects = list.map((opportunity) => opportunity.resolved);
      const total = list.length;

      const municipalityOptions = list
        .map((opportunity) => opportunity.municipality)
        .filter((value, index, array) => array.indexOf(value) === index)
        .filter((municipality) => municipality)
        .map((municipality) => ({ text: municipality || '', value: municipality?.toLowerCase() || '' }))
        .sort((a, b) => {
          if (a.text < b.text) return -1;
          if (a.text > b.text) return 1;
          return 0;
        });

      if (municipalityFilter.length > 0) {
        list = list.filter(
          (opportunity) => opportunity.municipality?.toLowerCase() === municipalityFilter.toLowerCase(),
        );
      }

      if (feedbackStatusFilter === 'correct') {
        list = list.filter((opportunity) => opportunity.resolved?.resolved);
      } else if (feedbackStatusFilter === 'incorrect') {
        list = list.filter((opportunity) => opportunity.resolved?.resolved === false);
      } else if (feedbackStatusFilter === 'missing') {
        list = list.filter((opportunity) => opportunity.resolved === undefined);
      }

      list = list.filter(
        (opportunity) =>
          opportunity.tittel?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          opportunity.municipality?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          opportunity.serviceProviderName?.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      return {
        opportunityList: list,
        total: total,
        municipalityOptions: [
          {
            text: tCommon('none'),
            value: '',
          },
          ...municipalityOptions,
        ],
        notReviewed: resolvedObjects.filter((resolvedObject) => resolvedObject === undefined).length,
        sentCorrectly: resolvedObjects.filter((resolvedObject) => !!resolvedObject && resolvedObject.resolved)
          .length,
        sentIncorrectly: resolvedObjects.filter(
          (resolvedObject) => !!resolvedObject && resolvedObject.resolved === false,
        ).length,
      };
    }, [
      listQuery.data?.pages,
      serviceProviders,
      searchQuery,
      getMunicipalityNameByNumber,
      municipalityFilter,
      resolvedList,
      tCommon,
      feedbackStatusFilter,
    ]);

  return (
    <>
      <DocumentTitle title={documentTitle}>
        <Page>
          <h1>{tOneWayIn('opportunities')}</h1>
          {fromList.length > 0 && (
            <div className="margin-top--4">{tOneWayIn('opportunityDetails.MunicipalitiesList')}</div>
          )}
          {fromList.sort().length > 0 && (
            <div className="opportunities__municipalityList">
              {fromList.sort().map((municipalityName) => (
                <Tag className="opportunities__municipalityList--tags" key={municipalityName} type="info">
                  {municipalityName || ''}
                </Tag>
              ))}
            </div>
          )}
          {countyView && (
            <>
              <div className="margin-top--2">
                <Link to={ONE_WAY_IN.BASE}>
                  {tOneWayIn('goToMunicipalityView')} <FaIcon name={FaIcons.ChevronRightLight} />
                </Link>
              </div>
              <div className={'margin-top--2'}>
                <Notification type={'info'}>
                  {tOneWayIn('countyView.NoteOpportunitiesCanOnlyBeHandled')}
                </Notification>
              </div>
            </>
          )}
          <div className="margin-top--4">
            <InputField
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={tCommon('search')}
              className="width--60"
            />
          </div>
          <div className="display--flex justify-content--space-between align-items--center">
            <div>
              <Button
                theme="neutral"
                variant="outlined"
                disabled={!listQuery.hasNextPage || listQuery.isFetching}
                onClick={() => listQuery.fetchNextPage()}
              >
                {tOneWayIn('getMoreOpportunities')}
              </Button>
            </div>
            <Select
              label={tOneWayIn('filterByMunicipality')}
              options={municipalityOptions}
              onChange={(e) => setMunicipalityFilter(e.target.value)}
            />
            <Select
              label={tOneWayIn('feedbackFilter.Title')}
              options={feedbackFilterOptions}
              onChange={(e) => setFeedbackStatusFilter(e.target.value as FeedbackResolvedFilter)}
            />
          </div>
          <div className="margin-top--2 margin-bottom--2 display--flex justify-content--space-between align-items--center">
            {orderFromOldToNew && (
              <div>
                <Checkbox
                  name="orderFromOldToNew"
                  options={[{ text: tCommon('oldestToNewest'), value: '', selected: orderFromOldToNew.init }]}
                  onChange={(e) => orderFromOldToNew.set(e.currentTarget.checked)}
                />
              </div>
            )}
            {setFromDate && (
              <div>
                <DateInput
                  name="fromDate"
                  label={`${tCommon('from')} ${tCommon('date').toLowerCase()}`}
                  onChange={(event) => {
                    const { value } = event.target;
                    const endDate = value ? new Date(value) : undefined;
                    setFromDate(endDate);
                  }}
                />
              </div>
            )}
            {setToDate && (
              <div>
                <DateInput
                  name="toDate"
                  label={`${tCommon('to')} ${tCommon('date').toLowerCase()}`}
                  onChange={(event) => {
                    const { value } = event.target;
                    const toDate = value ? new Date(value) : undefined;
                    setToDate(toDate);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            {listQuery.isLoading && <LoadingPage />}
            {!listQuery.isLoading && listQuery.isError && (
              <Notification type="error" wrapperRole="alert">
                {tOneWayIn('errorCouldNotGetAllForms')}
              </Notification>
            )}
            {!!opportunityList.length && (
              <div className="display--flex margin-top--1 margin-bottom--1">
                <div>
                  <FaIcon size="lg" color="#2fcc72" name={FaIcons.CheckCircle} className="margin-right--1" />{' '}
                  {sentCorrectly}
                </div>
                <div className="margin-left--3">
                  <FaIcon size="lg" color="#ed0000" name={FaIcons.Times} className="margin-right--1" />{' '}
                  {sentIncorrectly}
                </div>
                <div className="margin-left--3">
                  <FaIcon size="xl" name={FaIcons.InfoSquare} className="margin-right--1" /> {notReviewed}
                </div>
              </div>
            )}
            {!listQuery.isLoading && total > 0 && (
              <div>
                {`${tOneWayIn('services.Displaying')} ${opportunityList.length} ${tOneWayIn(
                  'services.OfTotal',
                )} ${total}.`}
              </div>
            )}
            {!listQuery.isLoading && !listQuery.isError && opportunityList.length === 0 && (
              <div className="margin-top--2">{tOneWayIn('opportunityDetails.FoundNoOpportunities')}</div>
            )}
            {!listQuery.isLoading &&
              opportunityList.map((opportunity) => {
                return <OneWayInReviewListItem key={opportunity.id} opportunity={opportunity} />;
              })}
          </div>
        </Page>
      </DocumentTitle>
    </>
  );
};

export default OneWayInReviewOpportunities;
