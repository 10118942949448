import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import useDocumentTitle from 'src/hooks/use-document-title';
import saveAs from 'file-saver';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import { useSivaContractAllDataExtract } from 'src/features/siva/ideas-and-companies/hooks/use-siva-contract-data-extract';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';

const ExtractIdeasAndCompanies: React.FC = () => {
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { t: tSiva } = useTranslation('siva');
  const { documentTitle } = useDocumentTitle(tPageTitles('sivaReporting'));

  const { download } = useSivaContractAllDataExtract();

  return (
    <DocumentTitle title={documentTitle}>
      {download.isLoading ? (
        <LoadingPage />
      ) : (
        <Page>
          <div className="stack-large">
            <h1>{tSiva('export.ideasAndCompanies.header')}</h1>
            <TertiaryButton
              onClick={async () => {
                const file = await download.mutateAsync(true);
                saveAs(file.data, file.fileName);
              }}
              minimalPadding={true}
              icon={{ prefix: 'fal', iconName: 'file-export' }}
            >
              {tSiva('export.ideasAndCompanies.exportButton')}
            </TertiaryButton>
          </div>
        </Page>
      )}
    </DocumentTitle>
  );
};

export default ExtractIdeasAndCompanies;
