export function getSIVAYearList() {
  const initialReportYear = 2023;
  let thisYear = new Date().getFullYear();

  // We get the budgets for the year ahead in december. So need to look one year ahead.
  thisYear += 1;

  const yearArray = [] as number[];
  for (let i = initialReportYear; i <= thisYear; i++) {
    yearArray.push(i);
  }

  return yearArray.reverse();
}
