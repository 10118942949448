import { SivaGoalsDetailsDto } from 'src/api/v2';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { getExpectedValueInDataset } from './table-utils';

function AddTargetNumberWorksheetToWorkbook(
  workbook: ExcelJS.Workbook,
  year: number,
  goalsDetails: SivaGoalsDetailsDto[],
) {
  const worksheet = workbook.addWorksheet(year.toString());

  // Freezes first two columns in excel and makes it sticky
  worksheet.views = [{ state: 'frozen', xSplit: 2 }];

  let headers: string[] = [];
  const values: any[][] = [];

  // Headers are target number category and name, as well as the different actor names
  headers = ['Kategori', 'Måltall', ...goalsDetails.map((goaldetail) => goaldetail.clusterName)];

  // Get all the target
  const targetNumbersBase = goalsDetails.find((goalDetail) => !!goalDetail.goalAccomplishments);

  if (!targetNumbersBase) return;

  const targetNumbers = targetNumbersBase.goalAccomplishments
    .map((goalAccomplishment) => ({
      id: goalAccomplishment.goalDetail.id,
      name: goalAccomplishment.goalDetail.name,
      category: goalAccomplishment.goalCategory.name,
    }))
    .sort((a, b) => {
      const lowerA = a.category?.toLowerCase() || '';
      const lowerB = b.category?.toLocaleLowerCase() || '';

      if (lowerA > lowerB) return 1;
      if (lowerA < lowerB) return -1;

      return 0;
    });

  targetNumbers.forEach((targetNumber) => {
    const row: any[] = [];

    row.push(targetNumber.category);
    row.push(targetNumber.name);

    goalsDetails.forEach((goalDetail) => {
      row.push(getExpectedValueInDataset(goalDetail.clusterId, targetNumber.id, goalsDetails));
    });

    values.push(row);
  });

  const columns: Partial<ExcelJS.Column>[] = headers.map((header, index) => {
    const defaultWidth = 5;
    let widest = Math.max(defaultWidth, header.length);

    values.forEach((rowValues) => {
      if (rowValues[index]) {
        const str = rowValues[index] as string;
        if (str.length > widest) {
          widest = str.length;
        }
      }
    });

    return {
      header,
      key: header.replaceAll(' ', '_'),
      width: widest,
    };
  });

  worksheet.columns = columns;
  worksheet.getRow(1).font = {
    bold: true,
  };

  worksheet.addRows(values);
}

export function CreateTargetNumberSpecialExport(
  type: string,
  goalDetailsSets: Record<number, SivaGoalsDetailsDto[]>,
) {
  const filename = `Måltallsoversikt-${type}-${Object.keys(goalDetailsSets).join('_')}`;

  const workbook = new ExcelJS.Workbook();

  Object.entries(goalDetailsSets).forEach((entry) => {
    const [entryKey, entryValue] = entry;
    AddTargetNumberWorksheetToWorkbook(workbook, +entryKey, entryValue);
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    });

    saveAs(blob, `${filename}-${new Date().toJSON()}.xlsx`);
  });
}
