import { FaIcon, FaIcons } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ValidationError {
  status: 400;
  title: string;
  errors: Record<string, string[]>;
  additionalProperties?: {
    traceId?: string;
  };
}

function isValidationError(error: object): error is ValidationError {
  if (!('errors' in error)) return false;
  if (!('status' in error && error.status === 400)) return false;
  return true;
}

function ValidationErrorToastContent({ error }: { error: ValidationError }) {
  const { t: tBackend } = useTranslation('backendErrors');
  return (
    <div className="stack-small">
      <div className="display--flex align-items--baseline gap--2">
        <FaIcon className="color--red-100" name={FaIcons.ExclamationCircleLight} />
        <h3 className="margin-bottom--0">Det skjedde en feil</h3>
      </div>
      {Object.entries(error.errors).map(([key, values]) => (
        <div className="color--in-black-100" key={key}>
          {`${values.map((message) => tBackend(message as any)).join(', ')}`}
        </div>
      ))}
      {error.additionalProperties?.traceId && (
        <p>
          Du kan kontakte oss og oppgi denne koden om problemet vedvarer: {error.additionalProperties.traceId}
        </p>
      )}
    </div>
  );
}

export function toastIfValidationError(error: unknown) {
  if (!(error && typeof error === 'object')) return;
  const camelCaseError = Object.fromEntries(
    Object.entries(error).map(([key, value]) => [key[0].toLowerCase() + key.substring(1), value]),
  );
  if (isValidationError(camelCaseError)) {
    toast(<ValidationErrorToastContent error={camelCaseError} />, {
      toastId: 'ValidationError',
      updateId: camelCaseError.additionalProperties?.traceId,
      autoClose: false,
      position: 'bottom-center',
    });
  }
}
