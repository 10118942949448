import React from 'react';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type Props<TData extends object> = {
  table: Table<TData>;
  showRowsSelectedText: boolean;
  singularTranslation?: string;
  pluralTranslation?: string;
};

const DataTableRowsSelectedText = <TData extends object>({
  table,
  showRowsSelectedText,
  singularTranslation,
  pluralTranslation,
}: Props<TData>) => {
  const { t: tCommon } = useTranslation();

  if (!showRowsSelectedText) return <></>;

  const selectedRowCount = table.getSelectedRowModel().flatRows.length;
  const totalRowCount = table.getCoreRowModel().rows.length;
  const singularText = singularTranslation ? singularTranslation : tCommon('rowSelected').toLowerCase();
  const pluralText = pluralTranslation ? pluralTranslation : tCommon('rowsSelected').toLowerCase();

  return (
    <div>
      <span className="font-weight--bold">{`${selectedRowCount}`}</span>{' '}
      {`${tCommon('of')} ${totalRowCount} ${totalRowCount === 1 ? singularText : pluralText}`}
    </div>
  );
};

export default DataTableRowsSelectedText;
