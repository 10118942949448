import { SivaGoalsDetailsDto } from 'src/api/v2';

export function getExpectedValueInDataset(clusterId: string, goalId: string, dataset: SivaGoalsDetailsDto[]) {
  const goalDetails = dataset.find((goalDetails) => goalDetails.clusterId === clusterId);

  if (!goalDetails) return 0;

  const goal = goalDetails.goalAccomplishments.find(
    (goalAccomplishment) => goalAccomplishment.goalDetail.id === goalId,
  );

  if (!goal) return 0;

  return goal.expectedValue;
}
