import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { ExtractGoalTableType } from '../types/ExtractGoalTableType';

const columnHelper = createColumnHelper<ExtractGoalTableType>();

export const extractGoalsTableColumns = (year: number) => {
  return [
    columnHelper.accessor((row) => row.actorName, {
      id: 'actorName',
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.goalName, {
      id: 'targetNumberName',
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.thisYearNumber, {
      id: year.toString(),
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.previous1YearNumber, {
      id: (year - 1).toString(),
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.previous2YearNumber, {
      id: (year - 2).toString(),
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor((row) => row.previous3YearNumber, {
      id: (year - 3).toString(),
      cell: (info) => <span>{info.getValue()}</span>,
    }),
  ];
};
