import accountProfile from 'src/i18n/locales/nb/accountProfile.json';
import buttons from 'src/i18n/locales/nb/buttons.json';
import cluster from 'src/i18n/locales/nb/cluster.json';
import clusterOperation from 'src/i18n/locales/nb/clusterOperation.json';
import common from 'src/i18n/locales/nb/common.json';
import error from 'src/i18n/locales/nb/error.json';
import backendErrors from 'src/i18n/locales/nb/backendErrors.json';
import events from 'src/i18n/locales/nb/events.json';
import oneWayIn from 'src/i18n/locales/nb/oneWayIn.json';
import pageTitles from 'src/i18n/locales/nb/pageTitles.json';
import projects from 'src/i18n/locales/nb/projects.json';
import reports from 'src/i18n/locales/nb/reports.json';
import resourceBase from 'src/i18n/locales/nb/resourceBase.json';
import selfEffort from 'src/i18n/locales/nb/selfEffort.json';
import statistics from 'src/i18n/locales/nb/statistics.json';
import accountSettings from 'src/i18n/locales/nb/accountSettings.json';
import tableTitles from 'src/i18n/locales/nb/tableTitles.json';
import sidenav from 'src/i18n/locales/nb/sidenav.json';
import projectCategories from 'src/i18n/locales/nb/sidenav.json';
import projectTypes from 'src/i18n/locales/nb/projectTypes.json';
import financing from 'src/i18n/locales/nb/financing.json';
import changelog from 'src/i18n/locales/nb/changelog.json';
import siva from 'src/i18n/locales/nb/siva.json';
import clusterStatus from 'src/i18n/locales/nb/clusterStatus.json';
import onboarding from 'src/i18n/locales/nb/onboarding.json';

const nb = {
  accountProfile,
  buttons,
  cluster,
  clusterOperation,
  common,
  error,
  backendErrors,
  events,
  oneWayIn,
  pageTitles,
  projectCategories,
  projects,
  projectTypes,
  reports,
  resourceBase,
  selfEffort,
  statistics,
  accountSettings,
  tableTitles,
  sidenav,
  financing,
  changelog,
  siva,
  clusterStatus,
  onboarding,
};

export default nb;
