import React from 'react';
import { useParams, useRoutes } from 'react-router-dom';
import routes, {
  COMPANY_CONTRACTS,
  COMPANY_ESA_REPORTING,
  // COMPANY_GOAL_ACHIEVEMENT,
  COMPANY_INFO,
} from '../routes';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import useDocumentTitle from 'src/hooks/use-document-title';
import { useTranslation } from 'react-i18next';
import Page from 'src/components/Page/Page';
import SideNavigationMenu, { RouterLinks } from 'src/components/Layout/SideNavigationMenu/SideNavigationMenu';
import { ROUTES } from 'src/navigation';
import CompanyActiveTag from '../components/CompanyActiveTag';
import { PageParams } from '../types/PageParams';
import { convertIdToNumber } from '../utils/idConversion';
import { isSivaContractActive } from '../utils/isContractActive';
import SivaPhaseTag from '../../components/SivaPhaseTag';
import { getContractCurrentPhase } from '../utils/helpers';
import useCompanyContract from '../hooks/use-company-contract';
import { SivaPhase } from 'src/api/v2';

const CompanyDetails: React.FC = () => {
  const { contractId } = useParams<PageParams>();

  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { documentTitle } = useDocumentTitle(tCommon('companies'));

  const { company, isLoading, contract } = useCompanyContract(convertIdToNumber(contractId));

  const element = useRoutes(routes);

  const activePhases = React.useMemo(() => {
    if (company) {
      const phases = company.contracts.map((contract) => getContractCurrentPhase(contract.phases));
      const uniquePhases = phases.filter((phase, index, self) => {
        return index === self.findIndex((t) => t?.sivaPhase === phase?.sivaPhase);
      });
      return uniquePhases;
    }
    return null;
  }, [company]);

  const companyContractsCount = company?.contracts.length ?? 1;

  const routerLinks: RouterLinks[] = [
    {
      id: 1,
      isVisible: true,
      to:
        companyContractsCount === 1 && contract
          ? ROUTES.SIVA.IDEAS_AND_COMPANIES.COMPANY_DETAILS.INDEX(contract.numericId.toString())
          : COMPANY_CONTRACTS,
      icon: { prefix: 'fal', iconName: 'file-contract' },
      textContent:
        contract?.phases.at(-1)?.sivaPhase === SivaPhase.Idea
          ? `${tSiva('phase.Idea')}`
          : `${tSiva('company.details.sidenav.contracts', {
              count: companyContractsCount,
            })}`,
      exact: companyContractsCount === 1 && !!contract,
    },
    {
      id: 0,
      isVisible: !!company,
      to: COMPANY_INFO,
      icon: { prefix: 'fal', iconName: 'memo' },
      textContent: tSiva('company.details.sidenav.info'),
    },
    {
      id: 2,
      isVisible: contract?.phases.at(-1)?.sivaPhase !== SivaPhase.Idea,
      to: COMPANY_ESA_REPORTING,
      icon: { prefix: 'fal', iconName: 'money-check-dollar-pen' },
      textContent: tSiva('company.details.sidenav.ESAReporting'),
    },
    // TODO: Legg til når det er klart
    // {
    //   id: 3,
    //   isVisible: true,
    //   to: COMPANY_GOAL_ACHIEVEMENT,
    //   icon: { prefix: 'fal', iconName: 'chart-mixed' },
    //   textContent: tSiva('company.details.sidenav.GoalAchievement'),
    // },
  ];

  if (isLoading) {
    return (
      <DocumentTitle title={documentTitle}>
        <Page noPadding>
          <LoadingPage />
        </Page>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={documentTitle}>
      <Page noPadding>
        <div className="display--flex">
          {(company || contract?.phases.at(-1)?.sivaPhase !== SivaPhase.Idea) && (
            <SideNavigationMenu
              className="flex-basis--min-content"
              backButtonPath={ROUTES.SIVA.IDEAS_AND_COMPANIES.BASE}
              routerLinks={routerLinks}
            />
          )}
          <div className="flex--2">
            <div className="margin-top--5">
              <div className="display--flex gap--1">
                <CompanyActiveTag active={isSivaContractActive(contract)} />
                {activePhases &&
                  activePhases.map((phase) => {
                    return <SivaPhaseTag key={phase?.id} phase={phase?.sivaPhase} />;
                  })}
              </div>
              <h1>{company?.name || contract?.name || contractId}</h1>
            </div>
            <React.Suspense fallback={<LoadingPage />}>{element}</React.Suspense>
          </div>
        </div>
      </Page>
    </DocumentTitle>
  );
};

export default CompanyDetails;
