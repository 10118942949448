import { Button, Notification, TextArea } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInOpportunityStatusCreateDto, OneWayInStatus } from 'src/api/v2';
import { useUser } from 'src/features/authorization';
import { toastPromise } from 'src/utils/toast';
import useOneWayIn from '../../hooks/use-one-way-in';
import '../../styles/OneWayInStyle.scss';
import RegisterServiceSelect from './RegisterServiceSelect';

type Props = {
  opportunityId: string | undefined;
  updating: boolean;
  deativateModal: () => void;
};

const OneWayInRegisterService: React.FC<Props> = ({ opportunityId, updating }) => {
  const { t: tCommon } = useTranslation();
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  const { oneWayInOpportunityFormQuery, updateOneWayInOpportunityStatus } = useOneWayIn(opportunityId);
  const { displayName, user, isAadUser } = useUser();

  const [comment, setComment] = React.useState<string>('');
  const [registeredServideIds, setRegisteredServiceIds] = React.useState<string[]>([] as string[]);
  const [commentWarning, setCommentWarning] = React.useState<boolean>(false);
  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);

  const onRegisterServices = () => {
    if (registeredServideIds.length === 0) {
      setCommentWarning(true);
      return;
    }

    const promise = updateOneWayInOpportunityStatus.mutateAsync({
      oneWayInOpportunityId: oneWayInOpportunityFormQuery.data?.opportunity?.id,
      referenceClusterId: undefined,
      status: OneWayInStatus.Active,
      description: comment,
      createdOn: new Date(),
      closedReason: undefined,
      registeredServices: registeredServideIds,
      recommendedServices: [],
      clusterContactId: undefined,
      clusterUserId: isAadUser ? undefined : user?.clusterUserId,
      handlersName: displayName || undefined,
      sendFollowUpReminderOn: undefined,
    } as OneWayInOpportunityStatusCreateDto);

    toastPromise(promise, {
      pending: tOneWayIn('updateToast.Pending'),
      success: tOneWayIn('updateToast.Success'),
      error: tOneWayIn('updateToast.Error'),
    }).then(() => setShowSuccess(true));
  };

  if (showSuccess) {
    return <div>{tOneWayIn('success.addService')}</div>;
  }

  return (
    <>
      <div>{tOneWayIn('explanation.RegisterServices')}</div>
      <div className="margin-top--2 margin-bottom--2">
        <RegisterServiceSelect onChange={(selectedIds) => setRegisteredServiceIds(selectedIds)} hideLabel />
      </div>
      <div className="margin-top--2 margin-bottom--4">
        <TextArea
          label={tCommon('note')}
          onChange={(e) => setComment(e.target.value)}
          rows={5}
          maxCount={500}
          maxLength={500}
        />
      </div>
      {commentWarning && (
        <Notification type="error" fullWidth>
          {tOneWayIn('services.recommend.error.chooseOneService')}
        </Notification>
      )}
      <Button disabled={updating} className="margin-top--6" onClick={onRegisterServices}>
        {tOneWayIn('button.addService')}
      </Button>
    </>
  );
};

export default OneWayInRegisterService;
