import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFoundPage from 'src/components/NotFoundPage/NotFoundPage';

import { AdminRealTimeStatistics } from 'src/features/AdminStatistics';
import { AcceptTerms } from 'src/features/accept-terms';
import { AccountSettingsFeature } from 'src/features/account-settings';
import { AssignmentsFeature } from 'src/features/assignments';
import { useUser } from 'src/features/authorization';
import { ClusterChooserPage } from 'src/features/cluster-chooser';
import { ClusterOperationFeature } from 'src/features/cluster-operation';
import { FocusAreaDetails } from 'src/features/focus-areas';
import { HomePage } from 'src/features/home';
import { MainGoalDetails } from 'src/features/main-goals';
import { MyFinancing } from 'src/features/my-financing';
import { OneWayInFeature } from 'src/features/one-way-in';
import { OwiContactsPage } from 'src/features/owi-contacts';
import { CustomProjectCategoryDetail } from 'src/features/project-categories';
import { ProjectsFeature } from 'src/features/projects';
import { EventUserRegistration, UserActivation } from 'src/features/public';
import { REPORTS_ROUTE_BASE } from 'src/features/reports';
import { ResourceBaseFeature } from 'src/features/resource-base';
import { SivaPageWrapper } from 'src/features/siva';
import { StatisticsFeature } from 'src/features/statistics';
import { TermsOfCondition } from 'src/features/terms-and-conditions';
import { ROUTES } from '.';
import { ActorProfileFeature } from 'src/features/actor-profile';
import { UserContactInformation } from 'src/features/user-contact-info';
import { SivaAdminFeature } from 'src/features/siva-admin';
import { TrackingFeature } from 'src/features/tracking';
import useStatusPrograms from 'src/hooks/use-status-programs';
import ExtractFeature from 'src/features/siva-admin-extract/pages/ExtractFeature';

const SessionExipred = React.lazy(() => import('src/components/session-expired'));
const LandingPage = React.lazy(() => import('src/components/LandingPage/LandingPage'));

type Props = {
  user: ReturnType<typeof useUser>;
};

const RouterConfig: React.FC<Props> = ({ user: userProp }) => {
  const {
    isUserLoggedIn,
    hasUserClusters,
    isUserRegistered,
    hasUserAcceptedTerms,
    hasUserSessionExipred,
    user,
  } = userProp;

  const { isProgramOneWayIn, isProgramSiva } = useStatusPrograms();

  // Bruker har blitt utlogget
  if (hasUserSessionExipred) {
    return (
      <Routes>
        <Route path="/terms-of-use" element={<TermsOfCondition />} />

        <Route path="*" element={<SessionExipred />} />
      </Routes>
    );
  }

  // Bruker er ikke logget inn
  if (!isUserLoggedIn || !isUserRegistered) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/terms-of-use" element={<TermsOfCondition />} />
        <Route path="/user-activation/:activationCode" element={<UserActivation />} />
        <Route path="/event-registration/:eventId" element={<EventUserRegistration />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Bruker er logget inn, registrert, men ikke akseptert TOS
  if (isUserRegistered && !hasUserAcceptedTerms) {
    return (
      <Routes>
        <Route path="/" element={<AcceptTerms />} />
        <Route path="/terms-of-use" element={<TermsOfCondition />} />
        <Route path="/user-activation/:activationCode" element={<UserActivation />} />
        <Route path="/event-registration/:eventId" element={<EventUserRegistration />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  //Bruker er logget inn men har ingen klynger
  if (user && !hasUserClusters) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/terms-of-use" element={<TermsOfCondition />} />
        <Route path="/user-activation/:activationCode" element={<UserActivation />} />
        <Route path="/event-registration/:eventId" element={<EventUserRegistration />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Bruker er logget inn, men ikke valgt noe klynge
  if (user && !user?.chosenClusterId) {
    return (
      <Routes>
        <Route path="/" element={<ClusterChooserPage />} />
        <Route path="/terms-of-use" element={<TermsOfCondition />} />
        <Route path="/admin-statistics" element={<AdminRealTimeStatistics />} />
        <Route path="/user-activation/:activationCode" element={<UserActivation />} />
        <Route path="/event-registration/:eventId" element={<EventUserRegistration />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  // Bruker er logget inn, og alt er good
  return (
    <Routes>
      {/* Features */}
      {isProgramOneWayIn && !isProgramSiva ? (
        <Route path="/" element={<OneWayInFeature />} />
      ) : (
        <Route path="/" element={<HomePage />} />
      )}
      <Route path="/financing" element={<MyFinancing />} />
      <Route path="/projects/*" element={<ProjectsFeature />} />
      <Route path="/statistics/*" element={<StatisticsFeature />} />
      <Route path={`${ROUTES.ONE_WAY_IN.BASE}/*`} element={<OneWayInFeature />} />
      <Route path="/assignments/*" element={<AssignmentsFeature />} />
      <Route path="/resource-base/*" element={<ResourceBaseFeature />} />
      <Route path="/admin-statistics" element={<AdminRealTimeStatistics />} />
      <Route path="/account-settings/*" element={<AccountSettingsFeature />} />
      <Route path="/cluster-operation/*" element={<ClusterOperationFeature />} />
      <Route path="/actor-profile/*" element={<ActorProfileFeature />} />
      <Route path="/contact-information/*" element={<UserContactInformation />} />
      <Route path="/contacts" element={<OwiContactsPage />} />
      <Route path="/track/*" element={<TrackingFeature />} />

      {/* // TODO: Avklar hva vi evt burde gjøre med disse, passer ikke helt inn i patternet */}
      <Route path="/main-goals/:mainGoalId" element={<MainGoalDetails />} />
      <Route path="/focus-areas/:focusAreaId" element={<FocusAreaDetails />} />
      <Route path="/project-categories/:customProjectCategoryId" element={<CustomProjectCategoryDetail />} />

      {/* Gamle routes, som er flyttet under andre routes */}
      <Route path="/events/*" element={<Navigate to="/projects/events" replace />} />
      <Route path="/inkind/*" element={<Navigate to="/projects/inkind" replace />} />
      <Route path="/self-effort/*" element={<Navigate to="/projects/inkind" replace />} />
      <Route path="/reports/*" element={<Navigate to={REPORTS_ROUTE_BASE} replace />} />

      {/* SIVA routes */}
      <Route path={`${ROUTES.SIVA.BASE}/*`} element={<SivaPageWrapper />} />
      <Route path={`${ROUTES.SIVA.ADMIN.BASE}/*`} element={<SivaAdminFeature />} />
      <Route path={`${ROUTES.SIVA.ADMIN.EXTRACT.BASE}/*`} element={<ExtractFeature />} />

      {/* Public routes */}
      <Route path="/terms-of-use" element={<TermsOfCondition />} />
      <Route path="/choose-cluster" element={<ClusterChooserPage />} />
      <Route path="/user-activation/:activationCode" element={<UserActivation />} />
      <Route path="/event-registration/:eventId" element={<EventUserRegistration />} />

      {/* 404 */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RouterConfig;
