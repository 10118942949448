import React, { useState } from 'react';
import { Select, SelectOptionProps } from '@in/component-library';
import { useMemo } from 'react';
import useYearList from 'src/hooks/use-year-list';
import { useParams } from 'react-router-dom';
import ESAReporting from './ESAReporting';

const EsaYearSelect = () => {
  const yearList = useYearList(2023);

  const { year: paramYear } = useParams();
  const currentYear = new Date().getFullYear();
  const year = !paramYear ? currentYear : Number(paramYear);
  const [selectedYear, setSelectedYear] = useState(paramYear || year.toString());

  const yearOptions: SelectOptionProps[] = useMemo(() => {
    return yearList.map((item) => ({ value: item.toString(), text: item.toString() }));
  }, [yearList]);

  return (
    <div className="stack">
      <Select
        value={selectedYear}
        className="width--20"
        label="Velg år"
        name="year"
        placeholder={paramYear}
        options={yearOptions}
        onChange={setSelectedYear}
      />

      <ESAReporting year={Number(selectedYear)} />
    </div>
  );
};

export default EsaYearSelect;
