import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'src/components/DocumentTitle/DocumentTitle';
import Page from 'src/components/Page/Page';
import ReportingLink from 'src/features/siva/reporting/components/ReportingLink/ReportingLink';
import useDocumentTitle from 'src/hooks/use-document-title';
import { ROUTES } from 'src/navigation';

const ExtractOverview: React.FC = () => {
  const { t: tSiva } = useTranslation('siva');
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { documentTitle } = useDocumentTitle(tPageTitles('sivaDataExtract'));

  return (
    <DocumentTitle title={documentTitle}>
      <Page>
        <h1 className="margin-bottom--6">{tSiva('dataExtract')}</h1>
        <ul className="list-style--none">
          <li>
            <ReportingLink to={ROUTES.SIVA.ADMIN.EXTRACT.GOALS} title={tSiva('targetNumber')} />
          </li>
          <li>
            <ReportingLink
              to={ROUTES.SIVA.ADMIN.EXTRACT.GOALACCOMPLISHMENT}
              title={tSiva('company.details.sidenav.GoalAchievement')}
            />
          </li>
          <li>
            <ReportingLink
              to={ROUTES.SIVA.ADMIN.EXTRACT.IDEAS_AND_COMPANIES}
              title={tSiva('ideasAndCompanies.title')}
            />
          </li>
        </ul>
      </Page>
    </DocumentTitle>
  );
};

export default ExtractOverview;
