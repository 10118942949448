import React, { useEffect, useState } from 'react';
import DialogContainer, { DialogContainerProps } from 'src/components/DialogContainer/DialogContainer';
import { Button, FaIcons, FaIcon, Theme, ButtonVariant, RadioButton } from '@in/component-library';
import { Trans, useTranslation } from 'react-i18next';
import css from './CreateLinkToOpportunityForm.module.scss';
import TertiaryButton from 'src/components/TertiaryButton/TertiaryButton';
import useSiteimprove from 'src/hooks/use-siteimprove';
import { useCluster } from 'src/features/cluster';

const linkTemplate = (clusterId: string, returnToActor: boolean) =>
  `https://raskavklaring.innovasjonnorge.no?cid=${clusterId}&return_to_actor=${returnToActor}&from_system=flerbedriftsportal.no`;

const CopyToClipboardComponent: React.FC<{ link: string }> = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { t: tCommon } = useTranslation('common');

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <div className={`${css['clipboard-component']}`}>
      <button
        type="button"
        aria-label={tCommon('copyLink')}
        onClick={() => {
          navigator.clipboard.writeText(link);
          setIsCopied(true);
        }}
      >
        <FaIcon name={isCopied ? FaIcons.Check : FaIcons.Copy} />
        {isCopied ? tCommon('copied') : tCommon('copyLink')}
      </button>
    </div>
  );
};

const CreateLinkToOpportunityForm: React.FC<Omit<DialogContainerProps, 'children'>> = (props) => {
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);
  const [createError, setCreateError] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const siteimprove = useSiteimprove();
  const { cluster } = useCluster();

  const { t: tCommon } = useTranslation('common');
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const [shouldReferBack, setShouldReferBack] = useState<boolean | null>(null);

  const onCreateOpportunityFormLink = () => {
    setCreateError(false);

    if (!cluster || shouldReferBack === null) {
      setCreateError(true);
      return;
    }
    setGeneratedLink(linkTemplate(cluster?.id, shouldReferBack));
  };

  const onClose = () => {
    setGeneratedLink(null);
    setCreateError(false);
    setShouldReferBack(null);
    props.onClose();
  };
  return (
    <DialogContainer {...props} onClose={onClose}>
      <p>{tOneWayIn('addOpportunities.main')}</p>
      {!seeMore ? (
        <TertiaryButton
          minimalPadding
          onClick={() => {
            setSeeMore(true);
            siteimprove.log('OPPORTUNITY_ADD', 'ReadMore');
          }}
          className="mb-20"
        >
          {tCommon('readMore')}
        </TertiaryButton>
      ) : (
        <>
          <h3 id={css['seemore-title-1']} className={`margin-bottom--0 margin-top--0`}>
            {tOneWayIn('addOpportunities.seeMore.1')}
          </h3>
          <p>{tOneWayIn('addOpportunities.seeMore.2')}</p>
          <h3 id={css['seemore-title-2']} className={`margin-bottom--0 margin-top--0`}>
            {tOneWayIn('addOpportunities.seeMore.3')}
          </h3>
          <p className="margin-bottom--0">{tOneWayIn('addOpportunities.seeMore.4')}</p>
          <TertiaryButton minimalPadding onClick={() => setSeeMore(false)} className="mb-20">
            {tCommon('readLess')}
          </TertiaryButton>
        </>
      )}
      <p>
        <Trans t={tOneWayIn} i18nKey={'addOpportunities.referBack'} components={{ strong: <strong /> }} />
      </p>
      <RadioButton
        name="referToActor"
        onChange={(e) => {
          if (e.currentTarget.value === 'yes') {
            setShouldReferBack(true);
          } else if (e.currentTarget.value === 'no') {
            setShouldReferBack(false);
          }
        }}
        options={[
          {
            selected: shouldReferBack == true,
            text: tCommon('yes'),
            value: 'yes',
            disabled: !!generatedLink,
          },
          {
            selected: shouldReferBack == false,
            text: tCommon('no'),
            value: 'no',
            disabled: !!generatedLink,
          },
        ]}
      />
      {createError ? (
        <p className={css['error-text']}>{tOneWayIn('addOpportunities.error')}</p>
      ) : generatedLink ? (
        <CopyToClipboardComponent link={generatedLink} />
      ) : (
        <>
          <Button
            disabled={shouldReferBack == null || !cluster}
            onClick={() => {
              siteimprove.log('OPPORTUNITY_ADD', 'Created');
              onCreateOpportunityFormLink();
            }}
            className="margin-right--2"
          >
            {tOneWayIn('addOpportunities.submit')}
          </Button>
          <Button onClick={onClose} theme={Theme.Neutral} variant={ButtonVariant.Outlined}>
            {tCommon('cancel')}
          </Button>
        </>
      )}
    </DialogContainer>
  );
};

export default CreateLinkToOpportunityForm;
