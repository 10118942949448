import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTableV3, { DataTableV3Actions } from 'src/components/DataTableV3';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import useESABills from '../hooks/use-esa-bills';
import { esaTableColumns } from '../constants/esa-table-columns';
import ESAPhaseBillsSummary from '../components/ESAPhaseBillsSummary';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';
import ESANoRegisteredBillsInfo from '../components/ESANoRegisteredBillsInfo';
import { Button, ButtonVariant, FaIcons, Notification, Spinner, Theme } from '@in/component-library';
import BillsForm from '../components/BillsForm';
import { calculateInnovationSupport, calculateSupportIntensity } from '../utils/calculations';
import { SivaEsaReportingDto, SivaPhase } from 'src/api/v2';
import { PageParams } from '../types/PageParams';
import { useParams } from 'react-router-dom';
import { convertIdToNumber } from '../utils/idConversion';
import { resources } from 'src/i18n';
import ESAPhaseTotalSummary from '../components/ESAPhaseTotalSummary';
import DialogContainer from 'src/components/DialogContainer/DialogContainer';
import useToggle from 'src/hooks/use-toggle';
import { useAuth } from 'src/features/authorization';

type Props = {
  year: number;
};

const ESAReporting = ({ year }: Props) => {
  const { checkAccess } = useAuth();
  const isBusinessGarden = checkAccess('UI_SivaBusinessGarden').read;

  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');
  const { t: tTableTitles } = useTranslation('tableTitles');
  const { contractId } = useParams<PageParams>();

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showAddForm, setShowAddForm] = React.useState(false);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const { isActive, activate, deactivate } = useToggle(false);
  const [selectedBills, setSelectedBills] = useState<SivaEsaReportingDto[]>([]);

  const { sivaESABillsQuery, addNewESABillsMutation, updateESABillsMutation, deleteESABillsMutation } =
    useESABills(year, convertIdToNumber(contractId));
  const billsLength = useMemo(() => (sivaESABillsQuery.data || []).length, [sivaESABillsQuery.data]);

  const billPhaseGroups = React.useMemo(() => {
    const groupedBills = {};
    const bills = sivaESABillsQuery.data || [];

    const groups = [...new Set(bills.map((bill) => bill.sivaPhase))];
    if (groups.some((x) => x === SivaPhase.PreIncubation)) {
      groupedBills['phase.PreIncubation'] = bills.filter(
        (bill) => bill.sivaPhase === SivaPhase.PreIncubation,
      );
    }
    if (groups.some((x) => x !== SivaPhase.PreIncubation)) {
      groupedBills['phase.OtherPhases'] = bills.filter((bill) => bill.sivaPhase !== SivaPhase.PreIncubation);
    }

    return groupedBills;
  }, [sivaESABillsQuery.data]);

  const billsTableData = React.useMemo(() => {
    return (
      sivaESABillsQuery.data?.map((bill) => ({
        ...bill,
        sivaPhase: tSiva(`phase.${bill.sivaPhase}` as any, { defaultValue: bill.sivaPhase || '' }),
        innovationSupport: calculateInnovationSupport(bill.marketValue, bill.invoicedDeductible),
        supportIntensity: calculateSupportIntensity(bill.marketValue, bill.invoicedDeductible),
      })) || []
    );
  }, [sivaESABillsQuery.data, tSiva]);

  const onAddSubmit = async (bills: SivaEsaReportingDto[]) => {
    const filledBills = bills.map((bill) => ({
      ...bill,
      //Timezones could mess this up, so we're adding a few days
      date: new Date(year, 0, 15),
      innovationSupport: calculateInnovationSupport(bill.marketValue, bill.invoicedDeductible),
      supportIntensity: calculateSupportIntensity(bill.marketValue, bill.invoicedDeductible),
    }));

    addNewESABillsMutation.mutateAsync(filledBills).then(() => {
      setShowSuccessMessage(true);
      setShowAddForm(false);
    });
  };

  const onEditSubmit = async (bills: SivaEsaReportingDto[]) => {
    const filledBills = bills.map((bill) => ({
      ...bill,
      date: new Date(year, 0, 15),
      innovationSupport: calculateInnovationSupport(bill.marketValue, bill.invoicedDeductible),
      supportIntensity: calculateSupportIntensity(bill.marketValue, bill.invoicedDeductible),
    }));

    updateESABillsMutation.mutateAsync(filledBills).then(() => {
      setShowSuccessMessage(true);
      setShowEditForm(false);
    });
  };

  const onDeleteSubmit = async () => {
    deleteESABillsMutation.mutateAsync(selectedBills).then(() => {
      setShowSuccessMessage(false);
      setSelectedBills([]);
      deactivate();
    });
  };

  const actions: DataTableV3Actions<SivaEsaReportingDto> = {
    primary: [
      {
        label: tSiva('company.details.esa.bill.add'),
        variant: ButtonVariant.Link,
        theme: Theme.Neutral,
        icon: FaIcons.PlusLight,
        onClick: () => {
          setShowAddForm(true);
        },
      },
      {
        label: tSiva('company.details.esa.bill.edit'),
        variant: ButtonVariant.Link,
        theme: Theme.Neutral,
        icon: FaIcons.EditLight,
        onClick: () => {
          setShowEditForm(true);
        },
      },
      {
        label: tSiva('company.details.esa.bill.delete'),
        variant: ButtonVariant.Link,
        theme: Theme.Neutral,
        icon: FaIcons.EditLight,
        onClick: (rows) => {
          if (rows.length > 0) {
            setSelectedBills(rows);
            activate();
          }
        },
      },
    ],
  };

  if (sivaESABillsQuery.isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <h2>
        {`${tSiva('company.details.esa.header')}`} {year}
      </h2>
      <div>
        {showSuccessMessage && (
          <Notification
            fullWidth
            dismissable
            className="margin-bottom--6"
            onClose={() => setShowSuccessMessage(false)}
            type="success"
          >
            {tSiva('company.details.esa.bill.success')}
          </Notification>
        )}
        {billsLength === 0 && (
          <ESANoRegisteredBillsInfo onSubmit={onAddSubmit} isLoading={addNewESABillsMutation.isLoading} />
        )}
        {billsLength > 0 && !showEditForm && (
          <DataTableV3
            id={DataTableV3Ids.Siva.IdeasAndCompanies.CompanyDetails.ESA}
            disableGlobalFilter={true}
            data={billsTableData}
            columns={esaTableColumns}
            translationFn={(key: string) => tTableTitles(key as any)}
            texts={{
              globalFilterPlaceholder: tCommon('search'),
              rowsPerPage: tCommon('rowsPerPage'),
              of: tCommon('of'),
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableColumnEditor
            actions={actions}
            hideFilterText
          />
        )}
        {showAddForm && (
          <BillsForm
            header={tSiva('company.details.esa.bill.add')}
            numericId={Number(contractId)}
            onSubmit={onAddSubmit}
            isLoading={addNewESABillsMutation.isLoading}
            onCancel={() => setShowAddForm(false)}
          />
        )}
        {showEditForm && (
          <BillsForm
            header={tSiva('company.details.esa.bill.edit')}
            numericId={Number(contractId)}
            onSubmit={onEditSubmit}
            isLoading={updateESABillsMutation.isLoading}
            onCancel={() => setShowEditForm(false)}
            bills={sivaESABillsQuery.data ? [...sivaESABillsQuery.data] : undefined}
            hideRemoveOption={true}
          />
        )}
      </div>
      <ESAPhaseTotalSummary bills={sivaESABillsQuery.data ?? []} />
      <div>
        {Object.keys(billPhaseGroups).map((billGroup) => {
          return (
            <ESAPhaseBillsSummary
              key={billGroup}
              header={billGroup as keyof (typeof resources)['nb']['siva']}
              bills={billPhaseGroups[billGroup]}
              isBusinessGarden={isBusinessGarden}
            />
          );
        })}
      </div>
      <DialogContainer title={tSiva('company.details.esa.bill.delete')} onClose={deactivate} show={isActive}>
        <ul>
          {selectedBills.map((bill) => (
            <li key={bill.id}>{bill.invoiceNumber}</li>
          ))}
        </ul>
        <div className="display--flex gap--2">
          <Button onClick={onDeleteSubmit}>
            {deleteESABillsMutation.isLoading ? <Spinner /> : tSiva('company.details.esa.bill.delete')}
          </Button>
          <Button onClick={deactivate}>{tCommon('cancel')}</Button>
        </div>
      </DialogContainer>
    </>
  );
};

export default ESAReporting;
