import React, { useState } from 'react';
import { BrregSearch, Checkbox, FaIcons, IconButton, listItem } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import ValueField from 'src/components/ValueField/ValueField';
import { useBrregSearch } from 'src/hooks/use-brreg-search';
import { useDebounce } from 'usehooks-ts';
import PhaseChangeCompanyCheck from '../../components/PhaseChangeCompanyCheck';
import { SivaPhase } from 'src/api/v2';

type Props = {
  phase: SivaPhase;
  selectedCompanyName?: string | undefined;
  orgNumber?: string;
  setOrgNumber: React.Dispatch<React.SetStateAction<string>>;
  setSelectedCompanyName: React.Dispatch<React.SetStateAction<string>>;
  setCompanyError: React.Dispatch<React.SetStateAction<boolean>>;
  showNoCompanyCheckbox: boolean;
};

const ConnectCompany: React.FC<Props> = ({
  phase,
  selectedCompanyName,
  orgNumber,
  setOrgNumber,
  setSelectedCompanyName,
  setCompanyError,
  showNoCompanyCheckbox,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tSiva } = useTranslation('siva');

  const [options, setOptions] = useState<listItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasCompany, setHasCompany] = useState<boolean>(true);
  const searchValueDebounced = useDebounce(searchValue, 100);

  const selectedOrgNumber = React.useMemo(() => {
    if (orgNumber) {
      return orgNumber;
    }
    return '';
  }, [orgNumber]);

  useBrregSearch(searchValueDebounced, {
    onSuccess: (data) => {
      if (data._embedded) {
        const options = data._embedded.enheter.map((item) => {
          return {
            organizationNumber: item.organisasjonsnummer,
            name: item.navn,
            address: item.forretningsadresse
              ? `${item.forretningsadresse?.postnummer ?? ''} ${item.forretningsadresse?.kommune ?? ''}`
              : '',
          };
        });
        setOptions(options);
      }
    },
  });
  return (
    <>
      {!selectedCompanyName && !orgNumber ? (
        <>
          <BrregSearch
            id="brregSearch"
            // TODO: finn en bedre måte å markere at den ikke er valgfri i visse tilfeller, må da stoppe submit
            label={
              !showNoCompanyCheckbox
                ? `${tSiva('newCompany.brregSearch.search.label')}*`
                : tSiva('newCompany.brregSearch.search.label')
            }
            name="brregSearch"
            className="margin-top--2"
            onChange={(e) => setSearchValue(e.currentTarget.value ?? '')}
            list={[]}
            selected={[]}
            options={options}
            disabled={!hasCompany}
            listItemOnClick={(item) => {
              const organizationNumber = item.currentTarget.value;
              setOrgNumber(organizationNumber);
              const found = options.find((option) => option.organizationNumber === organizationNumber);
              setSelectedCompanyName(found?.name ?? '');
            }}
          />
          {showNoCompanyCheckbox && (
            <Checkbox
              options={[
                {
                  value: 'false',
                  text: tSiva('contract.edit.noCompany.text'),
                  selected: false,
                },
              ]}
              onChange={(e) => {
                setOrgNumber('');
                setSelectedCompanyName('');
                setCompanyError(false);
                const { checked } = e.currentTarget;
                setHasCompany(!checked);
              }}
              name={'noCompany'}
            />
          )}
        </>
      ) : (
        <div className="display--flex gap--2 margin-top--2">
          <ValueField
            label={tCommon('company')}
            value={
              <div className="display--flex gap--2 align-items--center">
                {selectedCompanyName}
                <IconButton
                  ariaLabel={`${tCommon('edit')} ${tCommon('company').toLowerCase()}`}
                  iconName={FaIcons.EditLight}
                  iconSize="xl"
                  onClick={() => {
                    setOrgNumber('');
                    setSelectedCompanyName('');
                    setCompanyError(false);
                  }}
                />
              </div>
            }
          />
        </div>
      )}

      {selectedOrgNumber && phase && (
        <PhaseChangeCompanyCheck
          selectedPhase={phase}
          organizationNumber={selectedOrgNumber}
          setError={setCompanyError}
        />
      )}
    </>
  );
};

export default ConnectCompany;
