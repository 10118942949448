import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { GEONORGE_MUNICIPALITIES } from 'src/constants/hook-keys';
import { Municipalities2023Map } from '../constants/municipalities2023';

export type Municipality = {
  number: string;
  name: string;
};

const useGeonorge = () => {
  const municipalityQuery = useQuery(
    [GEONORGE_MUNICIPALITIES],
    () => fetch('https://ws.geonorge.no/kommuneinfo/v1/kommuner').then((data) => data.json()),
    {
      retry: 1,
      staleTime: 30 * 24 * 60 * 60 * 1000,
    },
  );

  const {
    municipalities,
    municipalityMap,
  }: { municipalities: Municipality[]; municipalityMap: Map<string, string> } = useMemo(() => {
    if (!municipalityQuery.data) {
      return { municipalities: [] as Municipality[], municipalityMap: new Map<string, string>() };
    }

    const municipalities = municipalityQuery.data.map((x) => ({
      number: x.kommunenummer,
      name: x.kommunenavnNorsk,
    }));

    return {
      municipalities,
      municipalityMap: new Map<string, string>(
        municipalities.map((municipality) => [municipality.number, municipality.name]),
      ),
    };
  }, [municipalityQuery.data]);

  const getMunicipalityNameByNumber = useCallback(
    (number: string | undefined) => {
      if (number == undefined) return null;
      let name = municipalityMap.get(number);
      if (!name) {
        // Check if municipality name is in the 2023 municipality list
        name = Municipalities2023Map.get(number);
      }

      return name;
    },
    [municipalityMap],
  );

  return {
    municipalities,
    getMunicipalityNameByNumber,
  };
};

export default useGeonorge;
