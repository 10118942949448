import React from 'react';
import useCompanyContract from '../hooks/use-company-contract';
import { useParams } from 'react-router-dom';
import { PageParams } from '../types/PageParams';
import { useTranslation } from 'react-i18next';
import { Button, ButtonVariant, Theme } from '@in/component-library';
import { convertIdToNumber } from '../utils/idConversion';

type Props = {
  onCancel: () => void;
};

const RevertContract: React.FC<Props> = ({ onCancel }) => {
  const { contractId } = useParams<PageParams>();
  const { revertSivaContractMutation } = useCompanyContract(convertIdToNumber(contractId));

  const { t: tCommon } = useTranslation();
  const { t: tSiva } = useTranslation('siva');

  const onSubmit = async () => {
    await revertSivaContractMutation.mutateAsync(convertIdToNumber(contractId));
  };

  return (
    <div className="width--60">
      <h2 className="margin-bottom--5">{tSiva('company.details.contract.revertPhase')}</h2>
      <p>{tSiva('company.details.contract.revertContract.warning')}</p>
      <p>{tSiva('company.details.contract.revertContract.cantUndo')}</p>
      <div className="display--flex gap--2">
        <Button
          theme={Theme.Neutral}
          type="submit"
          onClick={onSubmit}
          isLoading={revertSivaContractMutation.isLoading}
          disabled={revertSivaContractMutation.isLoading}
        >
          {tSiva('company.details.contract.revertPhase')}
        </Button>
        <Button
          theme={Theme.Neutral}
          variant={ButtonVariant.Outlined}
          type="button"
          onClick={onCancel}
          isLoading={revertSivaContractMutation.isLoading}
          disabled={revertSivaContractMutation.isLoading}
        >
          {tCommon('cancel')}
        </Button>
      </div>
    </div>
  );
};

export default RevertContract;
