import { InputField } from '@in/component-library';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInServiceProviderDto } from 'src/api/v2';
import DataTableV3 from 'src/components/DataTableV3';
import QueryParamMultiSelect from 'src/components/Filters/QueryParamMultiSelect';
import ResetFilterButton from 'src/components/ResetFilterButton/ResetFilterButton';
import useOneWayIn from 'src/features/one-way-in/hooks/use-one-way-in';
import useActorTypes from '../../hooks/use-actor-types';
import useCounties from '../../hooks/use-counties';
import useGeonorge from 'src/features/one-way-in/hooks/use-geonorge';
import { Counties, County } from 'src/features/one-way-in/constants/counties';
import useMunicipalities from '../../hooks/use-municipalities';
import useServices from '../../hooks/use-services';
import usePortfolioIndustries from '../../hooks/use-portfolio-industries';
import { useSearchParams } from 'react-router-dom';
import { DataTableV3Ids } from 'src/constants/dataTableIds';
import { referralColumns } from '../../constants/referral-columns';

import {
  filterproviderCounty,
  filterproviderMunicipality,
  filterproviderTitle,
  filterServices,
  filterproviderPortfolioIndustries,
} from '../../utils/searchQueryHelper';
import useOneWayInServiceProviders from 'src/features/one-way-in/hooks/use-one-way-in-service-providers';

type Props = {
  formId: string | undefined;
  searchQuery: string;
  setSearchQuery: (value: React.SetStateAction<string>) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<OneWayInServiceProviderDto[]>>;
};

const useCountyNameToMunicipalitiesNameMap = () => {
  const { getMunicipalityNameByNumber } = useGeonorge();
  const getMunicipalityNames = useCallback(
    (county: County) =>
      county.municipalities.map(
        (municipalityId) => getMunicipalityNameByNumber(municipalityId) ?? municipalityId,
      ),
    [getMunicipalityNameByNumber],
  );

  return useMemo<ReadonlyMap<string, ReadonlyArray<string>>>(
    () => new Map(Counties.map((county) => [county.countyName, getMunicipalityNames(county)])),
    [getMunicipalityNames],
  );
};

const useMunicipalitiesFromSelectedCounties = (
  selectedCountyIds: string[],
  unfilteredMunicipalities: { text: string; value: string }[],
) => {
  const countyToMunicipality = useCountyNameToMunicipalitiesNameMap();
  return useMemo(() => {
    if (selectedCountyIds.length === 0) return unfilteredMunicipalities;
    const municipalitiesFromCounties = new Set(
      selectedCountyIds.map((countyId) => countyToMunicipality.get(countyId)).flat(),
    );
    return unfilteredMunicipalities.filter((a) => municipalitiesFromCounties.has(a.value));
  }, [countyToMunicipality, selectedCountyIds, unfilteredMunicipalities]);
};

const ChooseActor: React.FC<Props> = ({ formId, searchQuery, setSearchQuery, setSelectedRows }) => {
  const { cooperationServiceProviders } = useOneWayInServiceProviders();
  const { getMunicipalityNameByNumber } = useGeonorge();
  const tableServiceProviders = useMemo(() => {
    return cooperationServiceProviders.map((provider) => ({
      ...provider,
      municipalities: provider.municipalities
        .map((municipality) => getMunicipalityNameByNumber(municipality) || municipality)
        .sort(),
    }));
  }, [cooperationServiceProviders, getMunicipalityNameByNumber]);

  const { oneWayInOpportunityFormQuery } = useOneWayIn(formId);
  const { t: tCommon } = useTranslation();
  const { t: tTableTitles } = useTranslation('tableTitles');
  const { t: tOneWayIn } = useTranslation('oneWayIn');
  const { actorTypes } = useActorTypes(tableServiceProviders);
  const { counties } = useCounties(tableServiceProviders);
  const { municipalities } = useMunicipalities(tableServiceProviders);
  const { services } = useServices(tableServiceProviders);
  const { portfolioIndustries } = usePortfolioIndustries(tableServiceProviders);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    selectedCountyIds,
    selectedMunicipalityIds,
    selectedActorTypes,
    selectedServices,
    selectedIndustries,
  } = useMemo(() => {
    return {
      selectedCountyIds: searchParams.get('selectedCountyIds')?.split(',') ?? [],
      selectedMunicipalityIds: searchParams.get('selectedMunicipalityIds')?.split(',') ?? [],
      selectedActorTypes: searchParams.get('selectedActorTypes')?.split(',') ?? [],
      selectedServices: searchParams.get('selectedServices')?.split(',') ?? [],
      selectedIndustries: searchParams.get('selectedIndustries')?.split(',') ?? [],
    };
  }, [searchParams]);

  const municipalityOptions = useMunicipalitiesFromSelectedCounties(selectedCountyIds, municipalities);

  const filteredTableData = useMemo(() => {
    return (
      tableServiceProviders
        //Filter actor types
        .filter(
          (provider) =>
            selectedActorTypes.length === 0 || selectedActorTypes.includes(provider.clusterStatusCode),
        )
        //Filter counties
        .filter(
          (provider) =>
            selectedCountyIds.length === 0 ||
            (provider.county && selectedCountyIds.includes(provider.county)),
        )
        // Filter municipalities
        .filter((provider) => {
          if (selectedMunicipalityIds.length === 0) return true;
          let included = false;
          provider.municipalities.forEach((muni) => {
            if (selectedMunicipalityIds.includes(muni)) {
              included = true;
            }
          });
          return included;
        })
        //Filter services
        .filter((provider) => {
          if (selectedServices.length === 0) return true;
          let included = false;
          provider.deliveryTypes.forEach((service) => {
            if (selectedServices.includes(service)) {
              included = true;
            }
          });
          return included;
        })
        //Filter industries
        .filter((provider) => {
          if (selectedIndustries.length === 0) return true;
          let included = false;
          provider.portfolioIndustries?.forEach((industry) => {
            if (selectedIndustries.includes(industry.name)) {
              included = true;
            }
          });
          return included;
        })
    );
  }, [
    tableServiceProviders,
    selectedActorTypes,
    selectedCountyIds,
    selectedMunicipalityIds,
    selectedServices,
    selectedIndustries,
  ]);

  const searchedtableData = useMemo(() => {
    if (searchQuery === '') return filteredTableData;
    return filteredTableData.filter((actor) => {
      return (
        filterproviderTitle(actor, searchQuery) ||
        filterproviderMunicipality(actor, searchQuery) ||
        filterproviderCounty(actor, searchQuery) ||
        filterServices(actor, searchQuery) ||
        filterproviderPortfolioIndustries(actor, searchQuery)
      );
    });
  }, [searchQuery, filteredTableData]);

  function handleClearFilter() {
    setSearchParams(undefined, { replace: true });
  }

  return (
    <>
      {!oneWayInOpportunityFormQuery.isLoading && tableServiceProviders.length > 0 && (
        <div className="margin-top--6 margin-bottom--3">
          <InputField
            iconPlacement={'right'}
            hideError
            type="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={tOneWayIn('opportunitySearchInputPlaceHolder')}
          />
        </div>
      )}

      <section className="display--flex flex-wrap--wrap align-items--center margin-bottom--6 gap--2 margin-0-on-all-children">
        <QueryParamMultiSelect
          options={actorTypes}
          queryStringParam="selectedActorTypes"
          label={tOneWayIn('providerType')}
          placeHolder={tOneWayIn('chooseProviderType')}
          name="actorType"
        />
        <QueryParamMultiSelect
          options={counties}
          queryStringParam="selectedCountyIds"
          label={tCommon('county')}
          placeHolder={`${tCommon('chooseCounty')}`}
          name="county"
        />
        <QueryParamMultiSelect
          options={municipalityOptions}
          queryStringParam="selectedMunicipalityIds"
          label={tCommon('municipality')}
          placeHolder={tOneWayIn('chooseMunicipality')}
          name="municipality"
        />
        <QueryParamMultiSelect
          options={services}
          queryStringParam="selectedServices"
          label={tOneWayIn('serviceType')}
          placeHolder={tOneWayIn('services.recommend.steps.choose')}
          name="service"
        />
        <QueryParamMultiSelect
          options={portfolioIndustries}
          queryStringParam="selectedIndustries"
          label={tTableTitles('portfolioIndustries')}
          placeHolder={tOneWayIn('chooseServiceType')}
          name="industry"
        />

        <ResetFilterButton className="margin-top--2" onClick={handleClearFilter} />
      </section>

      <DataTableV3
        id={DataTableV3Ids.OneWayIn.OpportunityDetailsReferring}
        disableGlobalFilter={true}
        hideFilterText
        data={searchedtableData}
        columns={referralColumns}
        translationFn={(key: string) => {
          return tTableTitles(key as any);
        }}
        texts={{
          globalFilterPlaceholder: tCommon('search'),
          rowsPerPage: tCommon('rowsPerPage'),
          of: tCommon('of'),
        }}
        pageSizeOptions={[5, 10, 25, 100]}
        disableColumnEditor
        disableExport
        showRowsSelectedText
        rowsSelectedSingularTranslation={tOneWayIn('details.Referral.Table.RowSelectedText')}
        rowsSelectedPluralTranslation={tOneWayIn('details.Referral.Table.RowsSelectedText')}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

export default ChooseActor;
