import { RadioButton, Textarea } from '@in/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInServiceProviderDto } from 'src/api/v2';

type Props = {
  referTo: string[];
  actorMessage: string;
  setActorMessage: (value: React.SetStateAction<string>) => void;
  customerEmailSelection: boolean;
  setCustomerEmailSelection: (value: React.SetStateAction<boolean>) => void;
  customerMessage: string;
  setCustomerMessage: (value: React.SetStateAction<string>) => void;
};

const WriteReferral: React.FC<Props> = ({
  referTo,
  actorMessage,
  setActorMessage,
  customerEmailSelection,
  setCustomerEmailSelection,
  customerMessage,
  setCustomerMessage,
}) => {
  const { t: tOneWayIn } = useTranslation('oneWayIn');

  return (
    <div>
      <h3 className="header--bold">{tOneWayIn('referral.writeReferral.toActors')}</h3>
      <div className="margin-left--3">
        <span>{tOneWayIn('referral.writeReferral.receivers')}</span>
        <ul className="margin-top--0">
          {referTo.sort().map((row: string, index) => (
            <li key={index}>{row}</li>
          ))}
        </ul>
        <p className="margin-bottom--0">{tOneWayIn('referral.writeReferral.actorEmailDescription')}</p>
        <Textarea
          value={actorMessage}
          onChange={(e) => setActorMessage(e.target.value)}
          maxLength={500}
          maxCount={500}
        />
      </div>
      <h3 className="padding-top--3 header--bold">{tOneWayIn('referral.writeReferral.toCustomer')}</h3>
      <div className="margin-left--3">
        <p>{tOneWayIn('referral.writeReferral.customerEmailOption')}</p>
        <div className="width--fit-content">
          <RadioButton
            name={'customerEmailSelection'}
            options={[
              { value: 'yes', text: 'Ja', selected: customerEmailSelection },
              { value: 'no', text: 'Nei', selected: !customerEmailSelection },
            ]}
            onChange={(e) =>
              e.currentTarget.value === 'yes'
                ? setCustomerEmailSelection(true)
                : setCustomerEmailSelection(false)
            }
          />
        </div>

        {customerEmailSelection && (
          <div>
            <p className="margin-bottom--0">{tOneWayIn('referral.writeReferral.customerEmailDescription')}</p>
            <Textarea
              value={customerMessage}
              onChange={(e) => setCustomerMessage(e.target.value)}
              maxLength={500}
              maxCount={500}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default WriteReferral;
