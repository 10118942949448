import { Header, Language } from '@in/component-library';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import fbLogo from 'src/assets/img/nic_klynger_logo_positiv_nic-main.svg';
import sivaLogo from 'src/assets/img/siva_logo2018_col.svg';
import type { ModuleTypes } from 'src/features/authorization';
import { useAuth, useUser } from 'src/features/authorization';
import { useCluster } from 'src/features/cluster';
import { useOneWayInNewOpportunitiesNumber } from 'src/features/one-way-in';
import useStatusPrograms from 'src/hooks/use-status-programs';
import i18n from 'src/i18n';
import {
  ACCOUNT_SETTINGS_BASE_ROUTE,
  CLUSTER_CHOOSER_BASE_ROUTE,
  CONTACT_INFORMATION_ROUTE,
  ROUTES,
} from 'src/navigation';
import { useLocalStorage } from 'usehooks-ts';
import { AppLanguages, MenuItem, NavItem, NavItemModule } from './types';
import useNavItemModules from 'src/hooks/use-nav-item-modules';

const Navbar: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tPageTitles } = useTranslation('pageTitles');
  const { NavItemModules } = useNavItemModules();

  const { cluster } = useCluster();
  const { checkAccess } = useAuth();

  const { displayName, isUserLoggedIn, logOut } = useUser();
  const { alertOneWayInNewOpportunities } = useOneWayInNewOpportunitiesNumber();
  const { isProgramOneWayIn, isProgramSiva, isProgramCluster } = useStatusPrograms();

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = useCallback(
    (href: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigate(href);
    },
    [navigate],
  );

  const addAlertNumber = useCallback(
    (item: NavItemModule) => {
      if (item.path === ROUTES.ONE_WAY_IN.BASE && alertOneWayInNewOpportunities > 0) {
        return ` (${alertOneWayInNewOpportunities})`;
      }
      return '';
    },
    [alertOneWayInNewOpportunities],
  );

  // Hovedmeny
  const navItems = useMemo(() => {
    const navItems: NavItem[] = [];

    for (const item of NavItemModules) {
      const alertSuffix = addAlertNumber(item);

      if (
        // No module check
        item.module === undefined ||
        // If it's an array, make sure we pass at least one
        (Array.isArray(item.module) &&
          item.module.map((module) => checkAccess(module).read).includes(true)) ||
        // If it's only one, make sure we pass
        checkAccess(item.module as ModuleTypes).read === true
      ) {
        // Hvis "SivaCore" og bruker har tilgang til "SivaAdmin", ikke vis "SivaCore"
        if (item.module === 'SivaCore' && checkAccess('SivaAdmin').read === true) {
          continue;
        }

        navItems.push({
          text: `${tPageTitles(item.text)}${alertSuffix}`,
          active: location.pathname.startsWith(item.path),
          href: item.path,
          onClick: handleOnClick(item.path),
        });
      }
    }

    return navItems;
  }, [NavItemModules, addAlertNumber, checkAccess, tPageTitles, location.pathname, handleOnClick]);

  // Brukermeny
  const menuItems = useMemo(() => {
    const menuItems: MenuItem[] = [];

    if (checkAccess('AccountSettings').read && !isProgramCluster) {
      menuItems.push(
        {
          text: tPageTitles('myContactInformation'),
          href: CONTACT_INFORMATION_ROUTE,
        },
        {
          text: tPageTitles('mailSettings'),
          href: ROUTES.ACTOR_PROFILE.SETTINGS.EMAIL,
        },
        {
          text: tPageTitles('accessControls'),
          href: ROUTES.ACTOR_PROFILE.SETTINGS.ACCESS_CONTROL,
        },
        {
          text: tPageTitles('actorProfile'),
          href: ROUTES.ACTOR_PROFILE.BASE,
        },
      );
    }

    if (checkAccess('AccountSettings').read && isProgramCluster) {
      menuItems.push({
        text: tPageTitles('settings'),
        href: ACCOUNT_SETTINGS_BASE_ROUTE,
      });
    }
    // Viser alltid denne
    menuItems.push({
      text: tCommon('chooseActor'),
      href: CLUSTER_CHOOSER_BASE_ROUTE,
    });

    if (isUserLoggedIn) {
      menuItems.push({
        text: tCommon('logOut'),
        onClick: logOut,
      });
    }

    return menuItems;
  }, [checkAccess, isProgramCluster, tCommon, isUserLoggedIn, tPageTitles, logOut]);

  // Språk
  const [selectedLanguage, setSelectedLanguage] = useLocalStorage<AppLanguages>('lang', 'nb');

  const selectLanguageHandler = useCallback(
    (lang: AppLanguages) => {
      i18n.changeLanguage(lang);
      setSelectedLanguage(lang);
      document.documentElement.lang = lang;
    },
    [setSelectedLanguage],
  );

  const languages = useMemo(() => {
    const languages: Language[] = [
      {
        code: 'nb',
        isSelected: selectedLanguage === 'nb',
        name: 'Bokmål',
        onClick: () => selectLanguageHandler('nb'),
      },
      {
        code: 'nn',
        isSelected: selectedLanguage === 'nn',
        name: 'Nynorsk',
        onClick: () => selectLanguageHandler('nn'),
      },
      {
        code: 'en',
        isSelected: selectedLanguage === 'en',
        name: 'English',
        onClick: () => selectLanguageHandler('en'),
      },
    ];

    return languages;
  }, [selectLanguageHandler, selectedLanguage]);

  const { logoImgSrc, customLogoImgSrc } = useMemo(() => {
    if (isProgramSiva) {
      return {
        logoImgSrc: undefined,
        customLogoImgSrc: sivaLogo,
      };
    }

    if (isProgramOneWayIn) {
      return {
        logoImgSrc: undefined,
        customLogoImgSrc: undefined,
      };
    }

    return {
      logoImgSrc: fbLogo,
      customLogoImgSrc: fbLogo,
    };
  }, [isProgramOneWayIn, isProgramSiva]);

  if (!isUserLoggedIn) {
    return null;
  }

  return (
    <>
      <Header
        // Brukerinfo
        name={displayName || ''}
        companyName={cluster?.name || ''}
        loggedInAsLabel={displayName || ''}
        hotJarClass="data-hj-suppress"
        // Hovedmeny
        navItems={navItems}
        navAriaLabel={tCommon('mainMenu')}
        // Brukermeny
        menuItems={menuItems}
        // Språkvelger
        languages={languages}
        languagesLabel={`${tCommon('language')}:`}
        // Logo
        logoLink="/"
        logoImgSrc={logoImgSrc}
        logoAriaLabel={tCommon('toFrontpage')}
        customLogo={
          customLogoImgSrc ? (
            <Link to={'/'}>
              <img src={customLogoImgSrc} alt={tCommon('toFrontpage')} />
            </Link>
          ) : undefined
        }
        // Styling
        className="margin-bottom--0 app-navbar"
      />
    </>
  );
};

export default Navbar;
