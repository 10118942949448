import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OneWayInServiceProviderDto } from 'src/api/v2';

const useActorTypes = (actors: OneWayInServiceProviderDto[]) => {
  const { t: tCommon } = useTranslation();
  const actorTypes = useMemo(() => {
    return [...new Set(actors.map((actor) => actor.clusterStatusCode))].map((statusCode) => ({
      text: tCommon(`clusterStatus.${statusCode as 'OWI_ACTOR' | 'INCUBATOR' | 'BUSINESS_GARDEN'}`),
      value: statusCode,
    }));
  }, [actors, tCommon]);

  return { actorTypes };
};

export default useActorTypes;
