import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from '../routes';
import { useAuth } from 'src/features/authorization';
import NoAccess from 'src/components/NoAccess/NoAccess';
import { LoadingPage } from 'src/components/LoadingPage/LoadingPage';

const ExtractFeature = () => {
  const element = useRoutes(routes);
  const { checkAccess } = useAuth();

  const access = checkAccess('SivaAdmin').read;

  if (!access) {
    return <NoAccess />;
  }

  return <React.Suspense fallback={<LoadingPage />}>{element}</React.Suspense>;
};

export default ExtractFeature;
