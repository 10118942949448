import { OneWayInClosedReason, OneWayInOpportunityStatusCreateDto, OneWayInStatus } from 'src/api/v2';

const createOwiUpdateDto = (
  opprtunityId,
  status: OneWayInStatus,
  comment: string,
  userId: string | undefined,
  handlersName?: string | undefined,
  closedReason?: OneWayInClosedReason | undefined,
  sendFollowUpReminderOn?: Date,
): OneWayInOpportunityStatusCreateDto => ({
  oneWayInOpportunityId: opprtunityId,
  referenceClusterId: undefined,
  status: status,
  description: comment,
  closedReason: closedReason,
  registeredServices: [],
  recommendedServices: [],
  clusterContactId: undefined,
  clusterUserId: userId,
  handlersName: handlersName,
  sendFollowUpReminderOn: sendFollowUpReminderOn,
});

export default createOwiUpdateDto;
