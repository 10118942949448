import accountProfile from 'src/i18n/locales/nn/accountProfile.json';
import buttons from 'src/i18n/locales/nn/buttons.json';
import cluster from 'src/i18n/locales/nn/cluster.json';
import clusterOperation from 'src/i18n/locales/nn/clusterOperation.json';
import common from 'src/i18n/locales/nn/common.json';
import error from 'src/i18n/locales/nn/error.json';
import backendErrors from 'src/i18n/locales/nb/backendErrors.json';
import events from 'src/i18n/locales/nn/events.json';
import oneWayIn from 'src/i18n/locales/nn/oneWayIn.json';
import pageTitles from 'src/i18n/locales/nn/pageTitles.json';
import projects from 'src/i18n/locales/nn/projects.json';
import reports from 'src/i18n/locales/nn/reports.json';
import resourceBase from 'src/i18n/locales/nn/resourceBase.json';
import selfEffort from 'src/i18n/locales/nn/selfEffort.json';
import statistics from 'src/i18n/locales/nn/statistics.json';
import accountSettings from 'src/i18n/locales/nn/accountSettings.json';
import tableTitles from 'src/i18n/locales/nn/tableTitles.json';
import sidenav from 'src/i18n/locales/nn/sidenav.json';
import projectCategories from 'src/i18n/locales/nn/projectCategories.json';
import projectTypes from 'src/i18n/locales/nn/projectTypes.json';
import financing from 'src/i18n/locales/nn/financing.json';
import changelog from 'src/i18n/locales/nn/changelog.json';
import siva from 'src/i18n/locales/nn/siva.json';
import clusterStatus from 'src/i18n/locales/nn/clusterStatus.json';
import onboarding from 'src/i18n/locales/nn/onboarding.json';

const nn = {
  accountProfile,
  buttons,
  cluster,
  clusterOperation,
  common,
  error,
  backendErrors,
  events,
  oneWayIn,
  pageTitles,
  projectCategories,
  projects,
  projectTypes,
  reports,
  resourceBase,
  selfEffort,
  statistics,
  accountSettings,
  tableTitles,
  sidenav,
  financing,
  changelog,
  siva,
  clusterStatus,
  onboarding,
};

export default nn;
